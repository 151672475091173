import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CoreCommonModule } from '@core/common.module';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import {SwiperModule} from 'ngx-swiper-wrapper';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CourseComponent } from './course-list/course.component';
import { CourseItemComponent } from './course-item/course-item.component';
import { CourseDetailsComponent } from './course-details/course-details.component';
import { CourseLecturesComponent } from './lectures/course-lectures.component';
import { FormsModule } from '@angular/forms';
import { LectureMenuComponent } from './lectures/components/menu/lecture-menu.component';
import { LectureContentComponent } from './lectures/components/lecture-content/lecture-content.component';
import { PlyrModule } from 'ngx-plyr';
import { LandingPageComponent } from '../landing/landing-page.component';
import { CountryNameComponent } from '../country/country-name.component';
import { AccountSettingsService } from 'app/services/account-settings.service';
import { AuthGuard } from 'app/auth/helpers';
import { StudentProfileService } from 'app/services/student-profile.service';
import { LectureService } from 'app/services/lecture.service';
import { StudentAssignmentFormComponent } from './lectures/components/assignment-form/student-assignment-form.component';
import { FileUploadModule } from 'ng2-file-upload';
import { QuillModule } from 'ngx-quill';


const routes = [
 
  {
    path: 'my-learning',
    component: CourseComponent,
    canActivate: [AuthGuard],
    resolve: {
      profile: StudentProfileService
    }
  },


  {
    path: 'courses/:course-list/:id',
    component: CourseComponent,
    data: { animation: 'courses-list' }
  }, 

  {
    path: 'courses/:id',
    component: CourseDetailsComponent
  },
  {
    path: 'courses/:courseId/lecture/:lectureId',
    component: CourseLecturesComponent,
    data: { path: 'courses/:courseId/lecture/:lectureId' },

    resolve: {
      getLecture: LectureService,
      getStudent:StudentProfileService
    },
  }

];

@NgModule({
  declarations: [
    CourseComponent,
    CourseItemComponent,
    CourseDetailsComponent,
    CourseLecturesComponent,
    LandingPageComponent,
    CountryNameComponent,
    StudentAssignmentFormComponent,
    LectureMenuComponent,
    LectureContentComponent

  ],
  imports: [RouterModule.forChild(routes), 
    ContentHeaderModule, 
    TranslateModule, 
    CoreCommonModule,
    NgbModule,
    SwiperModule,
    CardSnippetModule,
    FileUploadModule,
    FormsModule,
    PlyrModule,
    QuillModule.forRoot()
  ],
  exports: [
      CourseComponent,
      CourseItemComponent,
      CountryNameComponent,
      CourseDetailsComponent,
      CourseLecturesComponent],
      providers: [AccountSettingsService,StudentProfileService]
})
export class CoursesModule {}
