<ng-container>
  <!-- collapsible title -->
  <a class="d-flex align-items-center"
    [ngClass]="'dropdown-item dropdown-toggle'">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- itemContent template -->

  <ng-template #itemContent>

   <span *ngFor="let translate of item.translate">
    <span *ngIf="translate.lang == appLanguage" [translate]="translate.title"
    [class]="isRTL?'text-margin-rtl':''">{{ item.title }}</span>
  </span>


  </ng-template>
  <!-- sub-menu item/collapsible -->
  <ul class="dropdown-menu" [class]="isRTL?'alignment-rtl':''" 
  [ngClass]="{ show: isShow }">


    <ng-container *ngFor="let item of item.subcategories">
      <!-- item -->
     
      <li category-menu-horizontal-item
        *ngIf="item.type == 'item'"
        [item]="item"
        [appLanguage] ="appLanguage">
      </li>
      <!-- collapsible -->
    
    </ng-container>
  </ul>

  
</ng-container>
