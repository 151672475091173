import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'course-item',
  templateUrl: './course-item.component.html',
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ecommerce-application' }
})
export class CourseItemComponent implements OnInit {
  // Input Decorotor
  @Input() course:any;

  // Public
  public isInCart = false;
  public coreConfig: any;
  /**
   *
   * @param {CourseService} courseService
   */
  constructor(
    private router:Router) {}

  // Public Methods
  // -----------------------------------------------------------------------------------------------------
  showCourse(courseId:string){
    this.router.navigateByUrl(`courses/${courseId}`);
  }


  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------
  ngOnInit(): void {
    this.coreConfig = JSON.parse(localStorage.getItem('config'));

  }
}
