import { Component, OnInit,Input, SimpleChanges} from '@angular/core';
import { Router } from '@angular/router';
import { Chapter } from 'app/models/chapter';


@Component({
  selector: 'lecture-menu',
  templateUrl: './lecture-menu.component.html'

})

export class LectureMenuComponent implements OnInit {
  // public

  // Input Decorator
  @Input() chapters: Chapter;
  @Input() isRTL: boolean;
  @Input() chapterId: any;
  @Input() courseId: any;
  @Input() lectureId: any;
  constructor(
    private router:Router,

    ) {
    
    }



    showLectureAsset(lectureId){
      this.router.navigateByUrl(`courses/${this.courseId}/lecture/${lectureId}`);
    }
    
  ngOnInit(): void {

  }


}
