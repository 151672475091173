<ng-container *ngIf="!item.hidden">
  <!-- collapsible title -->
  <a class="d-flex align-items-center" 
   *ngIf="!item.url" (click)="toggleOpen($event)">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- itemContent template -->
  <ng-template #itemContent>
    <span *ngFor="let translate of item.translate">
    <span  class="menu-title text-truncate"  *ngIf="translate.lang == appLanguage" [translate]="translate.title"
    [class]="isRTL?'text-margin-rtl':''">{{ item.title }}</span>
 </span>
  </ng-template>

  <!-- sub-menu item/collapsible -->
  <ul class="menu-content">
    <ng-container *ngFor="let item of item.subcategories">
      <!-- item -->
      <li
        category-menu-vertical-item
        [item]="item"
        [appLanguage] ="appLanguage"
        [isRTL]="isRTL"
         >
        <span class="d-none"></span>
      </li>
      <!-- collapsible -->
    </ng-container>
  </ul>
</ng-container>
