import { Component, OnInit } from '@angular/core';
import * as snippet from 'app/layout/components/extensions/swiper/swiper.snippetcode';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { locale as english } from 'app/layout/page_translate/en';
import { locale as malay } from 'app/layout/page_translate/my';
import { locale as arabic } from 'app/layout/page_translate/ar';
import { CoreTranslationService } from '@core/services/translation.service';

@Component({
  selector: 'landing-page',
  templateUrl: './landing-page.component.html',
})

export class LandingPageComponent implements OnInit {
  // public
  public contentHeader: object;
  public shopSidebarToggle = false;
  public shopSidebarReset = false;
  public gridViewRef = true;
  public toggleMenu = true;
  public coreConfig: any;
  public isRTL:boolean;
  public _snippetCodeSwiperAutoplay = snippet.snippetCodeSwiperAutoplay;

  public swiperAutoplay: SwiperConfigInterface = {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false
    },
  
  };


  constructor(
    private _coreTranslationService: CoreTranslationService
   
    ) {
      this._coreTranslationService.translate(english,malay, arabic);
    }
   
 
  /**
   * On init
   */
  ngOnInit(): void {

    this.coreConfig = JSON.parse(localStorage.getItem('config'));
    this.isRTL = this.coreConfig.app.appLanguage=='ar' &&  this.coreConfig.layout.type=='horizontal'; 
  }


}
