<ng-container *ngIf="!item.hidden && 1!=1">
  <!-- collapsible title -->
  <a
    class="d-flex align-items-center"
    [ngClass]="item.classes"
    [ngClass]="'dropdown-toggle nav-link'"
>
    <ng-container *ngTemplateOutlet="itemContent">

    </ng-container>
  </a>

  <!-- itemContent template -->

  <ng-template #itemContent>
    <span data-feather="book-open"></span>
    <span translate="MENU.HOME.WHATDOYOUWANTLEARN"
    [class]="isRTL?'text-margin-rtl':''"></span>
  </ng-template>
  <!-- sub-menu item/collapsible -->
  <ul 
  
  class="dropdown-menu"
  [class]="isRTL?'alignment-rtl':''"
  [ngClass]="{ show: isShow }"
  >


    <ng-container *ngFor="let item of item">
      <!-- item -->
      <li category-menu-horizontal-collapsible
        [item]="item"
        class="dropdown dropdown-submenu"
      ></li>
    </ng-container>
  </ul>

  
</ng-container>
