<!-- vertical-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'vertical'">
  <admin-shell-layout></admin-shell-layout>
  </ng-container>
  <!-- / vertical-layout -->
  
  <!-- horizontal-layout -->
  <ng-container *ngIf="coreConfig.layout.type === 'horizontal'">
    <home-shell-layout></home-shell-layout>
  </ng-container>




<!-- / theme customizer -->
