<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul  class="nav navbar-nav flex-row">
    <li *ngIf="coreConfig.layout.type!='vertical'" class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
        
          <img   [src]="coreConfig.app.appLogoImage"  alt="brand-logo" style="min-width: 85px"/>&nbsp;
       
        </span>
        <p *ngIf="isRTL" class="brand-text mb-0" style="color:black;font-weight: 500;">كلاستيف</p>
        <h2 *ngIf="!isRTL" class="brand-text mb-0" style="color:black"></h2>
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>
<div class="navbar-container d-flex content">

    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
    <!--/ Menu Toggler -->
   <!-- Language -->
   <div class="bookmark-wrapper d-flex align-items-center">
 
    <!--/ Menu Toggler -->
   <!-- Language -->
   <ul  class="nav navbar-nav align-items-center ml-auto">
  <li ngbDropdown  class="'nav-item dropdown dropdown-language">
  <a class="nav-link dropdown-toggle" id="dropdown-flag" ngbDropdownToggle>
    <i class="flag-icon flag-icon-{{ languageOptions[_translateService.currentLang].flag }}"></i
    ><span [ngClass]="isRTL? 'selected-language text-margin-rtl font-rtl' : 'selected-language'"
>{{ languageOptions[_translateService.currentLang].title }}</span></a>
   <!-- Disable languages for now -->
<!-- <div ngbDropdownMenu aria-labelledby="dropdown-flag" 
  [ngClass]="isRTL? 'text-align-rtl font-rtl' : ''">
 
    <a *ngFor="let lang of _translateService.getLangs()" ngbDropdownItem 
    (click)="setLanguage(lang)">
      <i class="flag-icon flag-icon-{{ languageOptions[lang].flag }}"></i>
     <span [ngClass]="isRTL? 'text-margin-rtl font-rtl' : ''"> {{ languageOptions[lang].title }}</span> 
    </a>
  </div> -->
  </li>
</ul>
</div>

<div id="logo"></div>

  <ul [ngClass]="isRTL ? 'nav navbar-nav navbar-user-rtl' : 'nav navbar-nav align-items-center ml-auto'">

    <app-navbar-notification *ngIf="this.currentUser"></app-navbar-notification>
    <!--/ Notification -->
    <ng-container *ngIf="!this.currentUser">
      <span  [ngClass]="isRTL ? 'nav navbar-nav bookmark-icons ltr-layout font-rtl' : 'nav navbar-nav bookmark-icons'">
   
      
      
      <a type="button" [routerLink]="['/pages/authentication/login']" class=" mr-50  btn btn-outline-dark waves-effect">{{ 'login' | translate }}</a>
      <!-- <a type="button" [routerLink]="['/pages/authentication/register']" class="btn btn-gradient-dark" style="color:white">{{ 'signup' | translate }}</a> -->
      </span>
    </ng-container>
    <!-- User Dropdown -->
    <li   *ngIf="this.currentUser" ngbDropdown class="nav-item dropdown-user">
      <a 
        class="nav-link dropdown-toggle dropdown-user-link"
        id="dropdown-user"
        ngbDropdownToggle
        id="navbarUserDropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
     <ng-container *ngIf="this.currentUser">
          <div  
          [ngClass]="coreConfig.app.appLanguage=='ar' && coreConfig.layout.type=='horizontal'  ? 'user-nav d-sm-flex d-none user-nav-rtl' : 'user-nav d-sm-flex d-none'"
          >
            <span class="user-name font-weight-bolder"
              >{{ this.currentUser.name }}</span
            ><span class="user-status">
              {{currentUser.role=='Admin' ? ('admin' | translate)  : (currentUser.role=='Instructor' || currentUser.role=='InstructorCoach' )? ('instructor' | translate) : ('student' | translate) }}
          
            
            </span>
          </div>
          <span class="avatar"
            >
            
            <img
              class="round"
              [src]="this.currentUser.avatar?this.currentUser.avatar:'/assets/images/no-user-image.jpeg' "
              alt="avatar"
              height="40"
              width="40" />
              
              
              <span class="avatar-status-online"></span
          ></span>
        </ng-container>
      </a>

      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" 
      [ngClass]="isRTL ? 'dropdown-menu text-align-rtl' : 'dropdown-menu dropdown-menu-right'">
      
         <a *ngIf="currentUser.role == 'Instructor' || currentUser.role == 'InstructorCoach'  "  ngbDropdownItem [routerLink]="['/instructor/courses/dashboard']">
          <span [data-feather]="'credit-card'" [class]="'mr-50'"></span>
          <span [ngClass]="isRTL ? 'menu-list-margin-rtl' : ''">Dashboard</span> 
          </a>
          
     
        <div *ngIf="currentUser.role == 'Instructor' ||currentUser.role == 'InstructorCoach' "  class="dropdown-divider"></div>
        <a ngbDropdownItem [routerLink]="['/instructor/account-settings']"
          ><span [data-feather]="'settings'" [class]="'mr-50'"></span> Settings</a>
        
        <a ngbDropdownItem [routerLink]="['/pages/faq']"
          ><span [data-feather]="'help-circle'" [class]="'mr-50'"></span> FAQ</a
        ><a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a>
      </div>


      
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>
