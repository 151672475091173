<div class="content-wrapper container-xxl overflow-hidden p-0">

    <!-- Card Advance -->
    <div class="row match-height">
      <!-- Congratulations Card -->
      <div class="col-12 col-md-6 col-lg-7">
      
          <section id="component-swiper-autoplay">
            <core-card-snippet [snippetCode]="_snippetCodeSwiperAutoplay">
                <swiper class="swiper-autoplay swiper-container" [config]="swiperAutoplay">
                  <img class="img-fluid" src="assets/images/homepage/banner-01.jpeg" alt="banner"/>
                  <img class="img-fluid" src="assets/images/homepage/banner-02.jpeg" alt="banner" />
                  <img class="img-fluid" src="assets/images/homepage/banner-03.jpeg" alt="banner" />
                 
                </swiper>
             
            </core-card-snippet>
          </section>
       
      </div>
      <!--/ Congratulations Card -->

      <!-- Medal Card -->
      <div class="col-12 col-md-6 col-lg-5">
        <div class="card card-congratulation-medal">
          <div 
          [ngClass]="isRTL? 'card-body card-body-right' : 'card-body '" 
          
          >
            
            <h3 class="font-weight-bold">{{ 'card.how_to_write_thesis' | translate }}</h3>
            <p 
            [ngClass]="isRTL? 'card-text card-alignment-rtl' : 'card-text card-alignment'" 
         
            >{{ 'card.card_description' | translate }}</p>
        
            <button type="button" class="btn btn-primary" rippleEffect>{{ 'card.get_started_now' | translate }}</button>
            <img src="assets/images/illustration/badge.svg" 
            
          
            [ngClass]="isRTL ? 'medal-rtl' : 'congratulation-medal'" 

            
            alt="Medal Pic" width="10%" />
          </div>
        </div>
      </div>
      <!--/ Medal Card -->
    </div>



    
   <!--Become instructor -->
   <div class=" card user-card pricing-free-trial">
    <div class=" card-body row">
      <div [ngClass]="isRTL?'d-flex col-12 col-lg-10 col-lg-offset-3 mx-auto-rtl':'d-flex col-12 col-lg-10 col-lg-offset-3 mx-auto'">
        
        <div class="pricing-trial-content d-flex justify-content-center">
          <!-- image -->
          <img
            src="https://s.udemycdn.com/home/non-student-cta/instructor-1x-v3.jpg"
            class="pricing-trial-img img-fluid" style=" width: 100%;
            height: auto;"
           
          />
          <div class="text-center text-md-left mt-3 ml-5 "  style="display: flex;
          flex-direction: column;
          justify-content: center;
          max-width: 30rem;">
          <div [ngClass]="isRTL?'card-alignment-rtl':''">
            <h3 class="text-primary">{{ 'card.become_an_instructor' | translate }}</h3>
            <span class="d-flex card-text" style="font-size: 1.3rem;line-height:1.8;">{{ 'card.become_an_instructor_desc' | translate }}</span>
            <br> <br>
            <div class="d-flex flex-wrap">
              <a routerLink="" class="btn btn-primary" rippleEffect
                >{{ 'card.start_teaching_today' | translate }}</a>
            </div>  
          </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
 
<!-- End Become instructor -->

  <!--Become instructor -->
  <div class=" card user-card pricing-free-trial">
    <div class=" card-body row">
      <div 
      
      [ngClass]="isRTL?'d-flex col-12 col-lg-10 col-lg-offset-3 mx-auto-rtl':'d-flex col-12 col-lg-10 col-lg-offset-3 mx-auto'"
      >
        <div class="pricing-trial-content d-flex justify-content-center">
       
          <div class="text-center text-md-left mt-3 ml-5 "  style="display: flex;
          flex-direction: column;
          justify-content: center;
          max-width: 30rem;">
          <div [ngClass]="isRTL?'card-alignment-rtl':''">
            <h3 class="text-primary">{{ 'card.transform_your_life' | translate }}</h3>
            <span class="d-flex card-text" style="font-size: 1.3rem;line-height:1.8;">{{ 'card.transform_your_life_desc' | translate }}</span>
            <br> <br>
            <div class="d-flex flex-wrap">
              <a routerLink="" class="btn btn-primary" rippleEffect
                >{{ 'card.find_out_how' | translate }}</a>
            </div>  
          </div>
          </div>

             <!-- image -->
             <img
             src="assets/images/card/to_sun.jpeg"
             class="pricing-trial-img img-fluid" style=" width: 100%;
             height: auto;"
            
           />
          
        </div>
      </div>
    </div>
  </div>
 

</div>
