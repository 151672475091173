import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from 'app/auth/service';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'app/models/user';



// Support french & english languages.

@Injectable()
export class StudentProfileService implements Resolve<any> {
  coursesCount: any;
  currentUser: any;
  
  onSettingsChanged: BehaviorSubject<any>;

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient,
           private _studentService:AuthenticationService) {
    // Set the defaults
    this.onSettingsChanged = new BehaviorSubject({});

  }

  /**
   * Resolver
   *
   * @param {ActivatedRouteSnapshot} route
   * @param {RouterStateSnapshot} state
   * @returns {Observable<any> | Promise<any> | any}
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getStudentDetails()]).then(() => {
        resolve();
      }, reject);
    });
  }


  //------Update general info

  updateGeneral(generalInfo: FormData, userId:string): Observable<User> {
    return this._httpClient.put<User>(`${environment.apiUrl}users/instructor_general_info/${userId}`, generalInfo);
  }
  /**
   * Get rows
   */
   getStudentDetails(): Promise<any[]> {
    return new Promise((resolve) => {
      this.currentUser = this._studentService.currentUserValue;
      this.onSettingsChanged.next(this.currentUser);
      resolve(this.currentUser);
    });
  }
  /**
   * Get Student info
   */
   getStudentInfo(studentId: string): Observable<any>{
    return this._httpClient.get<any>(`${environment.apiUrl}users/${studentId}`);
  }
  /**
   * Get rows
   */
   getCourses(packageId: string): Observable<any>{
    return this._httpClient.get<any>(`${environment.apiUrl}courses/student-courses/${packageId}`);
  }
  /**
   * Get courses count
   */

   getCoursesCount(instructorId: string): Observable<any> {
    return this._httpClient.get<any>(`${environment.apiUrl}courses/instructor-courses/count/${instructorId}`);
  }

    /**
   * Get courses count
   */

  deleteCourse(instructorId: string,courseId: string): Observable<any> {

    return this._httpClient.delete<any>(`${environment.apiUrl}courses/instructor-courses/delete/${instructorId}/${courseId}`);
  }


}
