<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- Card Advance -->
  <!-- Horizontal Wizard -->
  <section class="content-wrapper container-xxl p-0 horizontal-wizard" style="margin-bottom:0.5rem!important;">
    <div id="stepper1" class="bs-stepper horizontal-wizard-example">
      <div class="bs-stepper-header btn-gradient-dark">
        <div class="step" data-target="#account-details">
          <button class="step-trigger">
            <span class="bs-stepper-label">
              <span class="bs-stepper-title white-text">{{'course.my_learning'|translate}}
              </span>
            </span>
          </button>
        </div>
        <div class="line"><i [data-feather]="isRTL?'chevron-left':'chevron-right'" class="font-medium-2 white-text"></i></div>
        <div class="step" data-target="#personal-info">
          <button class="step-trigger">
            <span class="bs-stepper-label">
              <a (click)="showCourse()" class="bs-stepper-title white-text">{{course?.title}}
              </a>
            </span>
          </button>
        </div>
     
      </div>
    
    </div>
  </section>
  <!--/ Horizontal Wizard -->

    <!-- account setting page -->
    <section id="faq">
      <div class="row nav-vertical d-flex">
        <div class="col-12 col-md-8 col-lg-8">
          <div class="card card-user-timeline">
            <div class="card-header">
              <div class="d-flex align-items-center">
                <i
                  *ngIf="isRTL"
                  data-feather="list"
                  class="user-timeline-title-icon flip-icon mr-1-rtl"
                  style="margin-right: 0rem"
                ></i>
                <i
                  *ngIf="!isRTL"
                  data-feather="list"
                  class="user-timeline-title-icon mr-1"
                ></i>

                <h3 class="card-title">
                  {{ lecture?.title}}
                </h3>
              </div>
            </div>
            
            <!-- Content -->
            <lecture-content
            [lecture]="lecture" 
            [isRTL]="isRTL" 
            [chapterId]="chapterId"
            [course]="course"
            [lectureId]="lectureId"
            ></lecture-content>
            <!-- End Content -->
        

          </div>
        </div>

        <!-- Titles List -->

        <div class="col-md-4">
          
          <!------------- Menu --------  -->

          <lecture-menu 
          [chapters]="chapters" 
          [isRTL]="isRTL" 
          [chapterId]="chapterId"
          [courseId]="courseId"
          [lectureId]="lectureId"
          ></lecture-menu>

          <!-- Collapse end -->
        </div>
      </div>
    </section>
    <!--/ account setting page -->
  </div>
</div>
