import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Input, Component, OnInit,OnDestroy, ViewChild, SimpleChanges, EventEmitter, Output, ElementRef, ChangeDetectorRef } from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';

import { FileUploader } from 'ng2-file-upload';
import { Subject } from 'rxjs';


import { ToastrService } from 'ngx-toastr';
import { Assignment } from 'app/models/Assignment';
import { Lecture } from 'app/models/lecture';
import { AssignmentService } from 'app/services/assignment.service';
import { AssignmentAnswer } from 'app/models/AssignmentAnswer';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'student-assignment-form',
  templateUrl: './student-assignment-form.component.html'

})

export class StudentAssignmentFormComponent implements OnInit, OnDestroy {
  @ViewChild('fileInputVariable') fileInputVariable: ElementRef;
  @ViewChild('assignmentEditForm') assignmentEditForm: NgForm;

  private readonly onDestroy: Subject<any> = new Subject<any>();

  public error:string;
  public uploadedFile:string| ArrayBuffer;
  isSubmitted = false;
  form:FormGroup;
  currentAssignmentAnswer:any;
  progress: number = 0;

  public editMode=false;
  public inputUpdated=false;
  public loading:boolean = false;
public assignmentAnswer:AssignmentAnswer;

  // Input Decorator
   @Input() assignment: Assignment;
   @Input() currentStudent: any;
   @Input() lecture: Lecture;
   @Input() isRTL: boolean;
   @Output() isUpdatedSuccess = new EventEmitter<boolean>();
   @Output() isAssignmentCanceled = new EventEmitter<boolean>();
   @Output() isInputUpdated = new EventEmitter<boolean>();

  // private
  private _unsubscribeAll: Subject<any>;
  // private


  public uploader: FileUploader = new FileUploader({
    isHTML5: true,
    queueLimit:2,
    disableMultipart:true,
    maxFileSize:100 * 1024 * 1024
   
 });
  /**
   * Constructor
   *
   */
   constructor(
    private formBuilder:FormBuilder,
    private _assignmentService:AssignmentService,
    private toastr: ToastrService
    ) { 
      this._unsubscribeAll = new Subject();
    }
  /**
 * On init --------------------------------------------------------
 */


  ngOnChanges(changes: SimpleChanges) {
    // on loading you can access childdata
    this._initForm();
    this.isUpdatedSuccess.emit(false);
    this.isAssignmentCanceled.emit(false);
    this.isInputUpdated.emit(false);
   
    this._getAssignmentAnswer();
    console.log(this.currentStudent._id);
  }

 private _getAssignmentAnswer() {
    this._assignmentService.getAssignmentAnswer(this.assignment._id,this.currentStudent._id).subscribe(assignmentAnswer => {
     if(assignmentAnswer[0])
      {
  
        this._setFormValues(assignmentAnswer[0]);
      this.assignmentAnswer = assignmentAnswer[0];
      this.currentAssignmentAnswer = assignmentAnswer[0];
     }


     })  
  }


  ngOnInit(): void {
   
  }

  /**
   * set value if this in update mode
   * 
   * @param assignmentAnswer 
   */    
   
   private _setFormValues(assignmentAnswer:any){
    this.assignmentForm.description.setValue(assignmentAnswer.description);
    this.assignmentForm.uploadedFile.setValue(assignmentAnswer.uploadedFile);
    this.assignmentForm.uploadedFileName.setValue(assignmentAnswer.uploadedFileName);
    this.assignmentForm.encodedFileName.setValue(assignmentAnswer.encodedFileName);
    this.assignmentForm.uploadedFileSize.setValue(assignmentAnswer.uploadedFileSize);
    this.assignmentForm.uploadedFileType.setValue(assignmentAnswer.uploadedFileType);
  }


      /* 
    Initiate form
    */
    private _initForm(){
      this.form=this.formBuilder.group({
        student:this.currentStudent._id,
        assignment:this.assignment._id,
        description: ['', Validators.required],
        uploadedFile:[''],
        uploadedFileName:'',
        uploadedFileSize:'',
        uploadedFileType:'',
        encodedFileName:''
      })
    
    }   

  inputChange(event:any){
    this.isInputUpdated.emit(true);
    this.inputUpdated = true;
    }
    
      
    /* 
    Scroll to form
    */

    ngAfterViewInit() {
      window.scrollTo(1900, 540);
    }


    /*
   Cancel any updates
   */ 
   cancelAssignment(){
      this.deleteUploadedFile();
      this.isAssignmentCanceled.emit(true);
    }

   
    deleteUploadedFile(){
      this.fileInputVariable.nativeElement.value = "";
      this.uploader.destroy();
      this.uploader.clearQueue();
     }


/**
 * upload file
 * @param event 
 * @returns 
 */


     uploadAssigmentFile(event:any){
      if (this.uploader.queue.length>1) {
        this.uploader.queue[0].remove();
      }
      const file = event.target.files[0];
  
      this.uploader.onWhenAddingFileFailed = (file) => {
      this.error = "File type is not supported";
      this.deleteUploadedFile();
      }
  
      if (file) {
    
        let uploadedFileName =this.uploader.queue[0]?.file?.name;
        let uploadedFileSize = (this.uploader.queue[0]?.file?.size / 1024 / 1024).toFixed(2)+' MB';
        let uploadedFileType =this.uploader.queue[0]?.file?.type;
    
        this.form.patchValue({uploadedFile:file});
        this.form.patchValue({uploadedFileName:uploadedFileName});
        this.form.patchValue({uploadedFileSize:uploadedFileSize});
        this.form.patchValue({uploadedFileType:uploadedFileType});
       
        this.form.get('uploadedFile').updateValueAndValidity();
        const fileReader = new FileReader();
        fileReader.onload = () =>{
          this.uploadedFile = fileReader.result
        };
        fileReader.readAsDataURL(file);
    }
  }


     assignmentFormSubmit(){

      this.isUpdatedSuccess.emit(false);
      this.isSubmitted = true;
  
      if (this.form.invalid) return;
  
      const assignmentAnswerFormData = new FormData();
      Object.keys(this.assignmentForm).map((key)=>{
        console.log(key,this.assignmentForm[key].value);
        assignmentAnswerFormData.append(key,this.assignmentForm[key].value);
       });
      
      this._updateOrCreateAssignment(assignmentAnswerFormData);
     }


     _updateOrCreateAssignment(assignmentFormData: FormData) {

      this._assignmentService.updateAssignmentAnswer(
        assignmentFormData,
         this.currentAssignmentAnswer?.id
       )
       .subscribe((event: HttpEvent<any>) => {
   
         switch (event.type) {
      
           case HttpEventType.Sent:
             break;
           case HttpEventType.ResponseHeader:
             break;
           case HttpEventType.UploadProgress:
             this.progress = Math.round(event.loaded / event.total * 100);
             this.loading = true;
   
             break;
           case HttpEventType.Response:
   
             this.currentAssignmentAnswer = event.body;
             this.assignmentAnswer = event.body;
             this.loading = false;
   
             this.isUpdatedSuccess.emit(true);
             this.isInputUpdated.emit(false);
             this.inputUpdated = false;
             
             this.toastr.success(this.editMode?'Content updated successfuly!':'Content created successfuly!', 'Success!', {
               toastClass: 'toast ngx-toastr',
               closeButton: true
              });

         setTimeout(() => {
               this.progress = 0;
             }, 1500);
             this.deleteUploadedFile();
         }
       },  (err) => {
         this.toastr.error(this.editMode?'Content wasn\'t updated!':'Content wasn\'t created!', 'Error!', {
           toastClass: 'toast ngx-toastr',
           closeButton: true
         });
       this.setErrorMessage(this.editMode?'Update failed, something went wrong':'Create document failed, something went wrong');
       this.loading = false; 
       });
     }

     setErrorMessage(message: string, timeOut = 3000) {
      this.error = message;
    this.ngAfterViewInit();
      setTimeout(() => {
        this.error = null;
      }, timeOut);
    }
     get assignmentForm(){
      return this.form.controls;
    }
    /**
   * On destroy
   */
     ngOnDestroy(): void {
      // Unsubscribe from all subscriptions
      this.onDestroy.next();
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();

    }
  
}
