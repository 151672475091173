<!-- Ecommerce Item -->
<div class="item-img text-center">
  <a (click)="showCourse(course.id)">
    <img class="img-fluid card-img-top" [src]="course.coverPhoto" alt="img-placeholder" />
  </a>
</div>
<div 

[ngClass]="coreConfig.app.appLanguage=='ar' ? 'card-body card-body-right' : 'card-body'">

  <h6 class="item-name">
    <a class="text-body" (click)="showCourse(course.id)"> {{ course.title }}</a>
 
  </h6>
 

  <p class="card-text item-description">
    {{ course.description }}
  </p>

  <p *ngIf="!course.isCoaching" class="card-text">
    {{ course.instructor.name }}
  </p>

  <div class="item-rating">
      
    <!-- <div class="rating rating-sm">
      <h6 class="item-rating">{{course.rating}}
      <ngb-rating [rate]="course.rating" [readonly]="true" [max]="5" 
      class="outline-none">
        <ng-template let-fill="fill" let-index="index"
          ><span class="fa fa-star-o mr-25" [class.fa-star]="fill === 100"></span
        ></ng-template>
      </ngb-rating></h6>
    </div> -->

  </div>

  <!-- <div class="item-wrapper">
    <div class="item-cost">
      <h6 class="item-price">
        {{course.isFree ? ('free' | translate)  : 'RM'+(course.price | number : '1.2')}}
      </h6>
    </div>
  </div> -->
  </div>

  <div class="item-options text-center">
    <div class="item-wrapper">
    <div class="item-cost">
      <h4 class="item-price"> {{course.isFree ? 'Free' : 'RM'+(course.price | number : '1.2')}}</h4>
</div>
</div>
</div>




<!--/ Ecommerce Item -->
