import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CustomBreakPointsProvider } from 'app/custom-breakpoints';
import { AdminShellLayoutModule } from 'app/layout/admin-shell/admin-shell-layout.module';
import { HomeShellLayoutModule } from './home-shell/home-shell-layout.module';


@NgModule({
  imports: [FlexLayoutModule.withConfig({ disableDefaultBps: true }), AdminShellLayoutModule,HomeShellLayoutModule],
  providers: [CustomBreakPointsProvider],
  exports: [AdminShellLayoutModule,HomeShellLayoutModule]
})
export class LayoutModule {}
