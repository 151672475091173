<!--Scroll to top-->
<button *ngIf="!isRTL"
  class="btn btn-primary btn-icon scroll-to-top"
  [ngClass]="{ 'show-scroll-to-top': windowScrolled }"
  type="button"
  (click)="scrollToTop()"
  rippleEffect
>
  <span [data-feather]="'arrow-up'"></span>
</button>

<button *ngIf="isRTL"
  class="btn btn-primary btn-icon scroll-to-top-rtl"
  [ngClass]="{ 'show-scroll-to-top': windowScrolled }"
  type="button"
  (click)="scrollToTop()"
  rippleEffect
>
  <span [data-feather]="'arrow-up'"></span>
</button>
