import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { Lecture } from 'app/models/lecture';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LectureService implements Resolve<any> {
  
  course:any;

  apiURLLectures = environment.apiUrl + 'lectures';

  public onLectureChanged: BehaviorSubject<any>;
  public lecture: any;


  constructor(private http: HttpClient) { 
     // Set the defaults
     this.onLectureChanged = new BehaviorSubject({});
  }


  /**
   * Resolver
   */

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return new Promise<void>((resolve, reject) => {
      Promise.all([this.getResolveLecture(route.paramMap.get('lectureId'),route.paramMap.get('courseId'))]).then(() => {
        resolve();
       
      }, reject);
    });
    }
    

 /**
   * Get lecture first
   */
 getResolveLecture(lectureId: string,courseId:any,): Promise<any[]> {
  return new Promise((resolve) => {
    this.getLecture(lectureId,courseId).subscribe(lecture=>{
      this.lecture = lecture;
      this.onLectureChanged.next(this.lecture);
       resolve(this.lecture);
    })  
   
  });
}

  /**
   * Get API Data
   */
  getLecture(lectureId: string,courseId: string): Observable<Lecture> {
    return this.http.get<Lecture>(`${this.apiURLLectures}/${lectureId}?course=${courseId}`);
  }
  


  getLectures(chapterId: string): Observable<Lecture[]> {
    return this.http.get<Lecture[]>(`${this.apiURLLectures}?chapter=${chapterId}`);
  }



  createLecture(category: Lecture): Observable<Lecture> {
    return this.http.post<Lecture>(this.apiURLLectures, category);
  }

  updateLecture(category: Lecture): Observable<Lecture> {
    return this.http.put<Lecture>(`${this.apiURLLectures}/${category.id}`, category);
  }

  deleteLecture(chapterId: string): Observable<any> {
  
    return this.http.delete<any>(`${this.apiURLLectures}/${chapterId}`);
  }

}
