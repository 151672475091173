import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: '[category-menu-vertical-item]',
  templateUrl: './category-vertical-item.component.html'
})
export class CategoryMenuVerticalItemComponent {
  @Input()
  item: any;
  @Input()
  isRTL: boolean;
  @Input()
  appLanguage: any;
  constructor( private router: Router) {}

  navigateCourseList(courses,courseId){
    var str = courses.replace(/\s+/g, '-');
    courses = str.toLowerCase();
    this.router.navigateByUrl(`courses/${courses}/${courseId}`);
  }

}
