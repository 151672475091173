import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoreMenuService } from '@core/components/core-menu/core-menu.service';
import { CoreMenu } from '@core/types/core-menu';
import { CoreCategoryMenu } from '@core/types/category-menu';
import { NavigationEnd, Router } from '@angular/router';
import { CategoryService } from 'app/services/category.service';

@Component({
  selector: '[core-menu]',
  templateUrl: './core-menu.component.html',
  styleUrls: ['./core-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoreMenuComponent implements OnInit {
  currentUser: any;

  @Input()
  layout = 'vertical';

  @Input()
  menu: any;
  public coreConfig:any;
  public isRTL:boolean;
  public isAdminPage:boolean;
  currentRoute:string;
  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   *
   * @param {ChangeDetectorRef} _changeDetectorRef
   * @param {CoreMenuService} _coreMenuService
   */
  constructor( private router: Router,
    private _changeDetectorRef: ChangeDetectorRef, 
    private _coreMenuService: CoreMenuService,
    private _categoryService:CategoryService) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }
  homePageItem:CoreMenu[] = [
    {
      id: 'home',
      title: 'Home',
      translate: 'MENU.HOME.HOME',
      type: 'item',
      icon: 'home',
      url: 'home'
    },
    ];

    categoryMenu:CoreCategoryMenu[] = [];

  // Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    
    this.coreConfig = JSON.parse(localStorage.getItem('config'));
   if(this.coreConfig)
   this.isRTL = this.coreConfig.app.appLanguage=='ar' &&  this.coreConfig.layout.type=='horizontal'; 
    
    this.isAdminPage =this.coreConfig?.layout.currentPage=='admin';

    this._categoryService.getCategoriesList().pipe(takeUntil(this._unsubscribeAll))
    .subscribe(response => {
      this.categoryMenu = response;

    });



    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (this.router.url.includes('courses')) {
            this.currentRoute = 'courses';
        }else{
          this.currentRoute = '';
        }
      }
    });


    // Set the menu either from the input or from the service
    this.menu = this.menu || this._coreMenuService.getCurrentMenu();

    // Subscribe to the current menu changes
    this._coreMenuService.onMenuChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.currentUser = this._coreMenuService.currentUser;

      // Load menu
      this.menu = this._coreMenuService.getCurrentMenu();

      this._changeDetectorRef.markForCheck();
    });
  }
}
