import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import { User } from 'app/models/user';

@Injectable({
  providedIn: 'root'
})
export class CoachingUserService {

  apiURLUsers = environment.apiUrl + 'users';

  public onCategoryEditChanged: BehaviorSubject<any>;
  public apiData: any;
  constructor(private http: HttpClient) { 
     // Set the defaults
     this.onCategoryEditChanged = new BehaviorSubject({});
  }

  getStudents(): Observable<User[]> {
    return this.http.get<User[]>(`${this.apiURLUsers}?role=StudentCoach`);
  }

  getInstructors(): Observable<User[]> {
    return this.http.get<User[]>(`${this.apiURLUsers}?role=InstructorCoach`);
  }

  /**
   * Get API Data
   */
   getStudent(userId: string): Observable<User> {
    return this.http.get<User>(`${this.apiURLUsers}/${userId}`);
  }
  

  createStudent(user: User): Observable<User> {
    return this.http.post<User>(this.apiURLUsers, user);
  }


  private handleError(error: any) { 
    let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return throwError(error);
  }

  
  updateCoachingUser(userData: FormData,userId:string): Observable<any> {
    if (userId) {

      return this.http.put(`${this.apiURLUsers}/coaching-user-update/${userId}`, userData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        catchError(this.handleError)); 
    }

    return this.http.post(`${this.apiURLUsers}/create-user-by-admin`, userData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        catchError(this.handleError));
  }

  /**
   * 
   * @param userId 
   * @returns 
   */

  deleteStudent(userId: string): Observable<any> {
  
    return this.http.delete<any>(`${this.apiURLUsers}/${userId}`);
  }

}
