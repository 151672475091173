
<ng-container >
  <span style="line-height: 2;">
  <!-- item.url -->
  <a  routerLink="courses/{{item.title}}/{{item._id}}"
    (click)="navigateCourseList(item.title,item._id)"
    [target]="'_self'">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>


  <ng-template #itemContent>
    <span data-feather="circle"></span>
    <span *ngFor="let translate of item.translate">
      <span *ngIf="translate.lang == appLanguage" [translate]="translate.title"
      [ngClass]="isRTL? 'menu-list-margin-right' : ''">{{ item.title }}</span>
    </span>
  </ng-template>
  </span>
</ng-container>
