import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from 'environments/environment';

import { catchError } from 'rxjs/operators';
import { Assignment } from 'app/models/Assignment';
import { AssignmentAnswer } from 'app/models/AssignmentAnswer';

@Injectable({
  providedIn: 'root'
})
export class AssignmentService {

  apiURLAssignments = environment.apiUrl + 'assignments';
  apiURLAssignmentAnswer = environment.apiUrl + 'assignment-answers';


  public onChapterEditChanged: BehaviorSubject<any>;
  public apiData: any;
  constructor(private http: HttpClient) { 
     // Set the defaults
     this.onChapterEditChanged = new BehaviorSubject({});
  }


  /**
   * Get API Data --------------------Assignments------------------
   */


   getAssignments(lectureId: string): Observable<Assignment[]> {
        return this.http.get<Assignment[]>(`${this.apiURLAssignments}?lecture=${lectureId}`);
      }
    

      
   getAssignment(assignmentId: string): Observable<Assignment> {
    return this.http.get<Assignment>(`${this.apiURLAssignments}/${assignmentId}`);
  }


  createAssignment(assignment: Assignment): Observable<Assignment> {
    return this.http.post<Assignment>(this.apiURLAssignments, assignment);
  }



  private handleError(error: any) { 
    let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return throwError(error);
  }




updateAssignment(assignmentData: FormData,assignmentId:string): Observable<any> {
  if (assignmentId) {
    return this.http.put(`${this.apiURLAssignments}/${assignmentId}`, assignmentData, {
      reportProgress: true,
      observe: 'events',
    })
    .pipe(
      catchError(this.handleError)); 
  }
  
  return this.http.post(`${this.apiURLAssignments}`, assignmentData, {
      reportProgress: true,
      observe: 'events',
    })
    .pipe(
      catchError(this.handleError));
}
// 



  updateMultiAssignments(assignments: any): Observable<any> {
    return this.http.put<any>(`${this.apiURLAssignments}/multiple-assignments`, assignments);
  }
  
  deleteAssignment(assignmentId: string): Observable<any> {
  
    return this.http.delete<any>(`${this.apiURLAssignments}/${assignmentId}`);
  }


  /**
   * Get API Data --------------------Answer------------------
   */


  getAssignmentAnswer(assignmentId: string,studentId:string): Observable<AssignmentAnswer> {
    console.log(`${this.apiURLAssignmentAnswer}/${assignmentId}/${studentId}`);
    return this.http.get<AssignmentAnswer>(`${this.apiURLAssignmentAnswer}/${assignmentId}/${studentId}`);
  }



  updateAssignmentAnswer(assignmentAnswerData: FormData,assignmentAnswerId:string): Observable<any> {
    if (assignmentAnswerId) {
      return this.http.put(`${this.apiURLAssignmentAnswer}/${assignmentAnswerId}`, assignmentAnswerData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        catchError(this.handleError)); 
    }
    
    return this.http.post(`${this.apiURLAssignmentAnswer}`, assignmentAnswerData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        catchError(this.handleError));
  }
  // 
}
