export const locale = {
  lang: 'ar',
  data: {

    auth:{
      email_password_wrong:'البريد الالكتروني او كلمة المرور غير صحيحة',
      please_sign_in:'الرجاء تسجيل الدخول إلى حسابك لتبدأ رحلة التعلم ',
      welcome_to_classtive:'مرحبًا بك في المنصة! 👋',
      email:'البريد الإلكتروني',
      password:'كلمة المرور',
      forget_password:'هل نسيت كلمة السر؟',
      remember_me:'تذكرنى',
      sign_in:'تسجيل الدخول',
      sign_up:'تسجيل جديد',
      new_on_platform:'هل انت جديد على منصتنا؟',
      create_an_account:'انشئ حساب',
      email_required:'البريد الإلكتروني مطلوب',
      email_must_valid:'يجب أن يكون البريد الإلكتروني عنوان بريد إلكتروني صالحًا',
      password_required:'كلمة المرور مطلوبة',
      email_already_exists:'البريد الالكتروني موجود بالفعل',
 
    },
    footer:{
      teach_on_courseel:'التدريس على كلاستيف',
      aboutus:' من نحن',
      contactus:'اتصل بنا',
      terms:'الشروط',
      privacy_policy:'سياسة الخصوصية',
      help_and_support:'المساعدة والدعم',
      mystudyin:'كلاستيف',
      copyright:'حقوق النشر',
      all_rights_reserved:'جميع الحقوق محفوظة',

    },
    card: {
      how_to_write_thesis: 'كيف تكتب اطروحة الدكتوراه؟',
      card_description:'اتخذ الخطوة الأولى نحو صياغة أطروحة أو أطروحة أو مشروع بحث عالي الجودة من خلال دورات تدريبية مخصصة من قبل مدربين مهرة.',
      get_started_now:'ابدأ الآن',
      become_an_instructor:'كن معلماً',
      become_an_instructor_desc:'يقوم المدربون من جميع أنحاء العالم بتدريس ملايين الطلاب في كلاستيف. نحن نقدم الأدوات والمهارات لتعليم ما تحب.',
      start_teaching_today:'ابدأ التدريس اليوم',
      transform_your_life:'غير حياتك من خلال التعليم',
      transform_your_life_desc:'يطلق المتعلمون في جميع أنحاء العالم وظائف جديدة ، ويتقدمون في مجالاتهم ، ويثري حياتهم.',
      find_out_how:'اكتشف ذلك الآن',
   
    },
    course: {
      lets_start_learning: 'لنبدأ التعلم',
      search_for_anything:'ابحث عن أي شيء',
      what_you_learn:'ما سوف تتعلمه',
      created_by:'انشأ بواسطة',
      last_updated:'اخر تحديث',
      go_to_course_content:'انتقل إلى محتوى الدرس',
      course_content:'محتويات الدرس',
      course_materials:'المادة التعليمية',
      choose_prefered_method:'اختر طريقتك المفضلة لتتعلم موضوع الدرس',
      exercise:'التمرين',
      optional:'اختياري',
      optional_desc:'التمرين اختياري يساعدك على اكتساب المزيد من الفهم',
      assignment:'المهمة',
      minutes_to_complete:'دقيقة لإكمال المهمة',
      mandatory:'مطلوب',
      students:'طلاب',
      courses:'دروس',
      rating:'التقييم',
      k:'الف',
      questions_comments:'الأسئلة والتعليقات',
      questions_comments_desc:'انتقل إلى أسئلة الطلاب الآخرين أو تعليقاتهم أو اترك تعليقك أو سؤالك حول هذا الموضوع',
      comments:'التعليقات',
      subject_explanantion:'شرح الموضوع',
      email_instructor:'تواصل مع المدرس',
      email_instructor_desc:'تواصل مع المدرس لمزيد من الفهم',
      email_Communication:'التواصل عبر البريد الإلكتروني',
      download_slide_btn:'انقر هنا لتحميل الشرائح',
      watch_video_btn:'انقر هنا لبدء مشاهدة الفيديو',
      this_course_includes:'يحتوي الدرس على:',
      hours:'ساعة',
      
      hours_video:'ساعة فيديو',
      
      articles:'مقالات',
      click_here_download_file:' اضغط هنا لتحميل الملف',
      requirements:'المتطلبات',
      my_learning:'دروسي',
      compressed:'ملف مضغوط',
      docs:'ملف وورد',
      powerpoint:'عرض تقدمي',
      video:'فيديو',
      audio:'تسجيل صوتي',
      slides:'شريحة',
      pdf:'PDF',
      pages:'صفحات',
      mints:'دقائق',


    },

    login:'دخول',
    signup:'تسجيل جديد',
    featured:'مميز',
    free:'مجاني',
    admin:'مدير',
    student:'طالب',
    instructor:'معلم',
    home:'الصفحة الرئيسية',
    account_setting:'اعدادات الحساب',
  }
};
