<div class="col-md-12 col-12">

  <div class=" card-body">

    <form class="form form-horizontal" 
    [formGroup]="form" 
    enctype="multipart/form-data" #assignmentEditForm="ngForm">

      <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
        <div class="alert-body">
          <p>{{error}}</p>
        </div>
      </ngb-alert>

      <div class="row">

          <div class="col-12">
            <h5>Question for this assignment</h5>
            <p *ngIf="!isRTL"  class="card-text grey-font" style="text-align:left;font-size: medium;" [innerHTML]="assignment.description">
            </p>
            <p *ngIf="isRTL" class="card-text grey-font" style="text-align:right;" [innerHTML]="assignment.description">
            </p>
            <br>
            <h5 >Resources for this assignment</h5>
            <a href="{{assignment.uploadedFile}}" target="_blank">
              <i data-feather="folder-plus" [ngClass]="isRTL ? 'ml-1' : 'mr-1'"></i>
              {{assignment.uploadedFileName}}
            </a>

            <br><br>
            <span *ngIf="assignmentAnswer?.isChecked">
            <h5>Your answer</h5>
            <p *ngIf="!isRTL" class="card-text grey-font" style="text-align:left;" [innerHTML]="assignmentAnswer.description">
            </p>
            <p *ngIf="isRTL" class="card-text grey-font" style="text-align:right;" [innerHTML]="assignmentAnswer.description">
            </p>
          </span>


            <div class="text-center form-group mb-2"  *ngIf="!assignmentAnswer?.isChecked">

              <h5>Add your submission</h5>

              <quill-editor name="description" 
              formControlName="description" 
              (input)="inputChange($event)">
              </quill-editor>
            </div>

            <small *ngIf="assignmentForm.description.invalid && isSubmitted" class="form-text text-danger">Assignment
              description is required</small>
          </div>


          <div class="col-sm-12" *ngIf="!assignmentAnswer?.isChecked">
            <label for="first-name">Supported File (Optional)</label>
            <div class="form-group row">

              <div class="col-sm-12">
                <div class="custom-file">
                  <input #fileInputVariable type="file" class="custom-file-input" type="file" ng2FileSelect
                    [uploader]="uploader" (change)="uploadAssigmentFile($event)" (input)="inputChange($event)"
                    name="uploadedFile" />

                  <label class="custom-file-label">Choose file</label>
                </div>
                <label for="file-upload-single">This file is going to be seen as a assignment extra.
                  Make sure everything is legible and the file size is less than 1 GiB.</label>
              </div>
            </div>
          </div>


          <div class="row">

            <div *ngIf="uploader.queue.length>0 || assignmentAnswer?.uploadedFileName" class="col-lg-12">

              <table class="table">
                <thead>
                  <tr>
                    <th width="50%">Name</th>
                    <th>Size</th>
                    <th>Progress</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of uploader.queue">
                    <td>
                      <strong>{{ item?.file?.name }}</strong>


                      
                    </td>
                    <td *ngIf="uploader.options.isHTML5" nowrap>
                      {{ item?.file?.size / 1024 / 1024 | number: '.2' }} MB
                    </td>
                    <td *ngIf="uploader.options.isHTML5">
                      <ngb-progressbar type="success progress-bar progress-bar-striped bg-success"
                        [value]="progress"></ngb-progressbar>
                    </td>
                    <td class="text-center">
                      <span *ngIf="item.isSuccess"><i data-feather="check"></i></span>
                      <span *ngIf="item.isCancel"><i data-feather="slash"></i></span>
                      <span *ngIf="item.isError"><i data-feather="x-circle"></i></span>
                    </td>
                    <td nowrap>

                      <button type="button" class="btn btn-raised btn-outline-danger btn-sm"
                        (click)="deleteUploadedFile()" rippleEffect>
                        <i data-feather="trash"></i>
                      </button>
                    </td>
                  </tr>

                  <!-- Uploaded file -->

                  <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="uploader.queue.length>0 && assignment">

                    <tr>
                      <td colspan="4" class="alert-body">
                        <p>Warning!: The previous file will be deleted - you can undo this step by deleting the new file
                          before saving</p>
                      </td>

                    </tr>

                  </ngb-alert>
                  <tr *ngIf="assignmentAnswer" [style]="uploader.queue.length>0 ?'background: #ff00003d;':''">
                    <td>
                      <strong>
                        
                        <a href="{{assignmentAnswer?.uploadedFile}}" target="_blank">
                          <i data-feather="folder-plus" [ngClass]="isRTL ? 'ml-1' : 'mr-1'"></i>
                          {{assignmentAnswer?.uploadedFileName}}
                        </a>  
                       </strong>
                    </td>
                    <td>
                      {{ assignmentAnswer?.uploadedFileSize }}
                    </td>
                    <td>
                      Complete
                    </td>
                    <td class="text-center">
                      <span><i data-feather="check"></i></span>
                    </td>
                    <td>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <br />
     

        <div *ngIf="!assignmentAnswer?.isChecked" class="ecol-sm-9 offset-sm-3">
          <button type="submit" (click)="assignmentFormSubmit()" rippleEffect class="btn btn-primary mr-1"
            [disabled]="loading || !inputUpdated">
            {{loading ? 'Please Wait...' :'Submit'}}

          </button>



          <button (click)="cancelAssignment()" rippleEffect class="btn btn-outline-secondary" [disabled]="loading">
            Cancel</button>
        </div>
      </div>
    </form>
  </div>
</div>