import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { Chapter } from 'app/models/chapter';
import { Lecture } from 'app/models/lecture';
import { Asset } from 'app/models/asset';

@Injectable({
  providedIn: 'root'
})
export class ChapterService {

  apiURLChapters = environment.apiUrl + 'chapters';
  apiURLLectures = environment.apiUrl + 'lectures';
  apiURLAssets = environment.apiUrl + 'assets';

  public onChapterEditChanged: BehaviorSubject<any>;
  public apiData: any;
  constructor(private http: HttpClient) { 
     // Set the defaults
     this.onChapterEditChanged = new BehaviorSubject({});
  }

  getChapters(courseId: string): Observable<Chapter[]> {

    return this.http.get<Chapter[]>(`${this.apiURLChapters}/getChapters?course=${courseId}`);
  }

  getChapterByLectureId(lectureId: string): Observable<any> {
    return this.http.get<any>(`${this.apiURLChapters}/getChapterByLecture/${lectureId}`);
  }

  /**
   * Get API Data
   */
   getChapter(chapterId: string): Observable<Chapter> {
    return this.http.get<Chapter>(`${this.apiURLChapters}/${chapterId}`);
  }


  createChapter(chapter: Chapter): Observable<Chapter> {
    return this.http.post<Chapter>(this.apiURLChapters, chapter);
  }

  updateChapter(chapter: Chapter): Observable<Chapter> {
    return this.http.put<Chapter>(`${this.apiURLChapters}/${chapter.id}`, chapter);
  }

  updateMultiChapters(chapters: any): Observable<any> {
  
    return this.http.put<any>(`${this.apiURLChapters}/multiple-chapters`, chapters);
  }
  
  deleteChapter(chapterId: string): Observable<any> {
  
    return this.http.delete<any>(`${this.apiURLChapters}/${chapterId}`);
  }


  /**
   * Get API Data --------------------Lectures------------------
   */


   getLectures(chapterId: string): Observable<Lecture[]> {
        return this.http.get<Lecture[]>(`${this.apiURLLectures}/getLectures?chapter=${chapterId}`);
      }
    

      
   getLecture(lectureId: string): Observable<Lecture> {
    return this.http.get<Lecture>(`${this.apiURLLectures}/${lectureId}`);
  }


  createLecture(lecture: Lecture): Observable<Lecture> {
    return this.http.post<Lecture>(this.apiURLLectures, lecture);
  }

  updateLecture(lecture: Lecture): Observable<Lecture> {
    return this.http.put<Lecture>(`${this.apiURLLectures}/${lecture.id}`, lecture);
  }

  updateMultiLectures(lectures: any): Observable<any> {
    return this.http.put<any>(`${this.apiURLLectures}/multiple-lectures`, lectures);
  }
  
  deleteLecture(lectureId: string): Observable<any> {
  
    return this.http.delete<any>(`${this.apiURLLectures}/${lectureId}`);
  }

 
  /**
   * Get API Data --------------------Assets------------------
   */


   getAssetsByLectureId(lectureId: string): Observable<Asset[]> {
    return this.http.get<Asset[]>(`${this.apiURLAssets}?lecture=${lectureId}`);
  }


  createAsset(asset: Asset): Observable<Asset> {
    return this.http.post<Asset>(this.apiURLAssets, asset);
  }

  updateAsset(lecture: Asset): Observable<Asset> {
    return this.http.put<Asset>(`${this.apiURLAssets}/${lecture.id}`, lecture);
  }

  updateMultiAssets(assets: any): Observable<any> {
    return this.http.put<any>(`${this.apiURLAssets}/multiple-assets`, assets);
  }
  
  deleteAsset(assetId: string): Observable<any> {
  
    return this.http.delete<any>(`${this.apiURLAssets}/${assetId}`);
  }
 
}
