<ng-container  *ngIf="!item.hidden && 1!=1">
  <!-- collapsible title -->
  <a class="d-flex align-items-center" 
   *ngIf="!item.url" (click)="toggleOpen($event)">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>

  <!-- itemContent template -->
  <ng-template #itemContent>
    <span data-feather="book-open"></span>
    <span class="menu-title text-truncate" translate="MENU.HOME.WHATDOYOUWANTLEARN"></span>
  </ng-template>

  <!-- sub-menu item/collapsible -->
  <ul class="menu-content">
    <ng-container *ngFor="let item of item">
      <!-- collapsible -->
      <li
        category-menu-vertical-collapsible
        [item]="item"
        class="nav-item has-sub"
      ></li>
    </ng-container>
  </ul>
</ng-container>
