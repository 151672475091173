<!-- Footer -->
<div class="">
  <div class="card-body">
    <div class="row">
  

      <div class="col-xl-4 col-lg-4 mt-2 mt-xl-0">
        <div class="user-info-wrapper">
          
          <div class="d-flex flex-wrap">
            <p class="card-text mb-0">{{ 'footer.help_and_support' | translate }}</p>
          </div>

          <div class="d-flex flex-wrap my-50">
            <p class="card-text mb-0">{{ 'footer.terms' | translate }}</p>
          </div>

          <div class="d-flex flex-wrap my-50">
            <p class="card-text mb-0">{{ 'footer.privacy_policy' | translate }}</p>
          </div>
        </div>
      </div>

      <div class="col-xl-4 col-lg-4 mt-2 mt-xl-0">
        <div class="user-info-wrapper">
          
          <div class="d-flex flex-wrap">
            <p class="card-text mb-0">{{ 'footer.teach_on_courseel' | translate }}</p>
          </div>

          <div class="d-flex flex-wrap my-50">
            <p class="card-text mb-0">{{ 'footer.aboutus' | translate }}</p>
          </div>

          <div class="d-flex flex-wrap my-50">
            <p class="card-text mb-0">{{ 'footer.contactus' | translate }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      {{ 'footer.copyright' | translate }} &copy; {{ year }}
      <a class="ml-25" href="#" target="_blank">{{ 'footer.mystudyin' | translate }}</a>
      <span class="d-none d-sm-inline-block">, {{ 'footer.all_rights_reserved' | translate }}</span>
    </span>
    <span class="float-md-right d-none d-md-block"> {{ 'footer.mystudyin' | translate }} </span>
  </p>
  <!--/ Footer -->

  <!-- Move to top Button-->
  <app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>

  <!-- Buynow Button-->
  <div class="buy-now">
    <a
      href="https://1.envato.market/coaching_admin"
      target="_blank"
      class="btn btn-danger"
      *ngIf="coreConfig.layout.buyNow"
      >Buy Now</a
    >
  </div>
</div>
