import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { locale as english } from 'app/layout/page_translate/en';
import { locale as malay } from 'app/layout/page_translate/my';
import { locale as arabic } from 'app/layout/page_translate/ar';
import { CoreTranslationService } from '@core/services/translation.service';
import { Course } from 'app/models/course';
import { Chapter } from 'app/models/chapter';
import { CourseService } from 'app/services/course.service';
import { ChapterService } from 'app/services/chapter.service';
import { LectureService } from 'app/services/lecture.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
  selector: 'course-lectures',
  templateUrl: './course-lectures.component.html',
  styleUrls: ['./course-lectures.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ecommerce-application' }
})
export class CourseLecturesComponent implements OnInit {
  // Input Decorotor

  public coreConfig:any;
  public isRTL:boolean;
    // public
  public contentHeader: object;

    // Input Decorator
   course: Course;
   chapters : Chapter[] =[];
   lecture:any;
   lectureId:any;
   chapterId:any;
   courseId:any;
  // private
  private _unsubscribeAll: Subject<any>;
  /**
   *
   * @param {CourseService} courseService
   *    * @param {ChapterService} chapterService
   */
  constructor(
    private courseService: CourseService,
    private chapterService: ChapterService,
    private lectureService: LectureService,
    private route:ActivatedRoute,
    private _coreTranslationService: CoreTranslationService,
    private router:Router,
    ) {
      this._unsubscribeAll = new Subject();
      this._coreTranslationService.translate(english, malay, arabic);
    }

 
private _getCourse() {
  this.route.params.subscribe(params=>{
    if(params.courseId){
      this.courseService.getCourse(params.courseId).subscribe(course=>{
        this.course = course;
      })
    }
  })
}

private _getChapters() {
  this.route.params.subscribe(params=>{
    if(params.courseId){
      this.courseId = params.courseId;
      this.chapterService.getChapters(params.courseId).subscribe(chapter=>{
        this.chapters = chapter;
      })
    }

    if(params.lectureId){
      this.lectureId = params.lectureId;
    }
  })
}


  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------
  ngOnInit(): void {
        // content header
        this._getLecture();
        this.coreConfig = JSON.parse(localStorage.getItem('config'));
        this.isRTL = this.coreConfig.app.appLanguage=='ar' &&  this.coreConfig.layout.type=='horizontal'; 

      }


      private _getLecture() {
        this.lectureService.onLectureChanged.pipe(takeUntil(this._unsubscribeAll)).
        subscribe(lecture => {
          this.lecture = lecture;
          this.chapterId = lecture.chapter;
          this._getCourse();
          this._getChapters();
        });
      }




  showCourse(){
    this.router.navigateByUrl(`courses/${this.courseId}`);
  }
  
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}
