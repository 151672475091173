import { Pipe, PipeTransform } from '@angular/core';
import { FormArray } from '@angular/forms';
@Pipe({
  name: 'filter',
  pure: false
})
export class FilterPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param {any[]} items
   * @param {string} searchText
   * @param {string} key
   *
   * @returns {any}
   */
  transform(items: any[], searchText?: any): FormArray[] {
    if (!items) return [];
    if (searchText['fileType'] === undefined) return items;
    if (!searchText['fileType']) return items;

   // searchText = searchText.toLowerCase();
    return items.filter(it => {


      searchText = searchText['fileType']?.toLowerCase();
      console.log(searchText);

     return it['fileType'].includes(searchText);
    });
  }
}
