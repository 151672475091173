<ng-container>
  <!-- item with router url -->
  <a
    class="d-flex align-items-center"
    routerLink="courses/{{item.title}}/{{item._id}}"
    (click)="navigateCourseList(item.title,item._id)"
    
    >
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </a>
  <ng-template #itemContent>
    <span data-feather="circle" [class]="isRTL? 'ml-1':''"></span>

      <span *ngFor="let translate of item.translate">
        <span  class="menu-title text-truncate" *ngIf="translate.lang == appLanguage" [translate]="translate.title"
        [ngClass]="isRTL? 'menu-list-margin-right' : ''"
        >{{ item.title }}</span>
      </span>
  
 
 
    </ng-template>
</ng-container>
