export const locale = {
  lang: 'ar',
  data: {
    MENU: {
      HOME: {
        HOME: 'الصفحة الرئيسية',
        DASHBOARD: 'لوحة التحكم',
        CONTACTUS: 'اتصل بنا',
        ABOUTUS: 'من نحن',
        TERMSCONDITIONS:'الاحكام والشروط',
        MYLEARNING: 'دروسي',
        WHATDOYOUWANTLEARN: 'ماذا تريد ان تتعلم اليوم؟',
        MATH: 'الرياضيات',
        ENGLISH: 'اللغة الانجليزية',
        TEST:'تجربة',
      },
      ADMIN: {
        CATEGORIES:'الفئات'
      }, 
    },
    //-------- END
  }
};
