import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import * as snippet from 'app/layout/components/extensions/swiper/swiper.snippetcode';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';

import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

import { locale as english } from 'app/layout/page_translate/en';
import { locale as malay } from 'app/layout/page_translate/my';
import { locale as arabic } from 'app/layout/page_translate/ar';
import { CoreTranslationService } from '@core/services/translation.service';
import { CourseService } from 'app/services/course.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { StudentProfileService } from 'app/services/student-profile.service';

@Component({
  selector: 'course',
  templateUrl: './course.component.html',
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ecommerce-application' }
})

export class CourseComponent implements OnInit {
  // public
  public contentHeader: object;
  public shopSidebarToggle = false;
  public shopSidebarReset = false;
  public gridViewRef = true;
  public courses;
  public wishlist;
  public cartList;
  public page = 1;
  public pageSize = 12;
  public searchText = '';
  public toggleMenu = true;
  public coreConfig: any;
  public isRTL:boolean;
  public _snippetCodeSwiperAutoplay = snippet.snippetCodeSwiperAutoplay;
  currentUser: any;
  private _unsubscribeAll: Subject<any>;
  public swiperAutoplay: SwiperConfigInterface = {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
  
  };

  /**
   *
   * @param {CoreSidebarService} _coreSidebarService
   * @param {CourseService} courseService
   */
  constructor(
    private _coreSidebarService: CoreSidebarService, 
    private courseService: CourseService,
    private _coreTranslationService: CoreTranslationService,
    private _studentService: StudentProfileService,
    private _changeDetectorRef: ChangeDetectorRef
   
    ) {
      this._coreTranslationService.translate(english,malay, arabic);
      this._unsubscribeAll = new Subject();
    }
   
    
  // Public Methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle Sidebar
   *
   * @param name
   */
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  /**
   * Update to List View
   */
  listView() {
    this.gridViewRef = false;
  }

  /**
   * Update to Grid View
   */
  gridView() {
    this.gridViewRef = true;
  }

  /**
   * Sort Product
   */

  // sortProduct(sortParam) {
  //   this.courseService.sortProduct(sortParam);
  // }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    this.coreConfig = JSON.parse(localStorage.getItem('config'));
    this.isRTL = this.coreConfig.app.appLanguage=='ar' &&  this.coreConfig.layout.type=='horizontal'; 


    // Current user
    this._studentService.onSettingsChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.currentUser = this._studentService.currentUser;

      this._studentService.getCourses(this.currentUser.coachingStudent.studentPackage).subscribe(res => {
        this.courses = res;
      
      }); 
 
    
      this._changeDetectorRef.markForCheck();
    });
  
  
  }


}
