import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { Course } from 'app/models/course';
@Injectable({
  providedIn: 'root'
})
export class CourseService {

  apiURLCourses = environment.apiUrl + 'courses';
  public onCourseEditChanged: BehaviorSubject<any>;
  public apiData: any;
  constructor(private http: HttpClient) { 
     // Set the defaults
     this.onCourseEditChanged = new BehaviorSubject({});
  }

  getCourses(): Observable<Course[]> {
    
    return this.http.get<Course[]>(this.apiURLCourses);
  }

  /**
   * Get API Data
   */
   getCourse(courseId: string): Observable<Course> {
    return this.http.get<Course>(`${this.apiURLCourses}/${courseId}`);
  }


  createCourse(course: FormData): Observable<Course> {
    return this.http.post<Course>(this.apiURLCourses, course);
  }

  updateCourse(courseData: FormData,courseId:string): Observable<Course> {

    return this.http.put<Course>(`${this.apiURLCourses}/${courseId}`, courseData);
  
  }

  updateDraftCourse(courseData: FormData,courseId:string,isPublished:boolean): Observable<Course> {
    return this.http.put<Course>(`${this.apiURLCourses}/update-draft/${courseId}?isPublished=${isPublished}`, courseData);
  }

  updateCourseItem(courseData: Course,courseId:string): Observable<Course> {
    return this.http.put<Course>(`${this.apiURLCourses}/${courseId}`, courseData);
  }

  deleteCourse(courseId: string): Observable<any> {
  
    return this.http.delete<any>(`${this.apiURLCourses}/${courseId}`);
  }

}
