export const locale = {
  lang: 'my',
  data: {
    MENU: {
      HOME: {
        HOME: 'Rumah',
        DASHBOARD: 'Papan pemuka',
        CONTACTUS: 'Hubungi Kami',
        ABOUTUS: 'Tentang kita',
        TERMSCONDITIONS:'Terma dan syarat',
        MYLEARNING: 'Pembelajaran Saya',
        WHATDOYOUWANTLEARN: 'apa yang anda ingin belajar?',
        MATH: 'Math',
        ENGLISH: 'English',
        TEST:'Testmy',
      },
      ADMIN: {
        CATEGORIES:'Categories Frenso'
      }, 
    },
    //-------- END
  }
};
