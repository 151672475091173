import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { locale as english } from 'app/layout/page_translate/en';
import { locale as malay } from 'app/layout/page_translate/my';
import { locale as arabic } from 'app/layout/page_translate/ar';
import { CoreTranslationService } from '@core/services/translation.service';
import { CourseService } from 'app/services/course.service';
import { LectureService } from 'app/services/lecture.service';


@Component({
  selector: 'course-details',
  templateUrl: './course-details.component.html',
  encapsulation: ViewEncapsulation.None,
  host: { class: 'ecommerce-application' }
})
export class CourseDetailsComponent implements OnInit {
  // Input Decorotor
  course: any;
  public coreConfig:any;
  public isRTL:boolean;
  lectureId:any;
  courseId:any;


    // public
  public contentHeader: object;
  /**
   *
   * @param {CourseService} courseService
   */
  constructor(
    private courseService: CourseService,
    private lectureService: LectureService,
    private route:ActivatedRoute,
    private router:Router,
    private _coreTranslationService: CoreTranslationService,
  

    ) {
      this._coreTranslationService.translate(english, malay, arabic);
    }

    showCourseLecture(){
      this.router.navigateByUrl(`courses/${this.courseId}/lecture/${this.lectureId}`);
    }
  

     


private _getCourse() {
 
  this.route.params.subscribe(params=>{
    
    if(params.id){
  this.courseId = params.id;
      this.courseService.getCourse(params.id).subscribe(course=>{
        this.course = course;
      
       this._getFirstLecture(course.chapters[0]._id);
      })
    }
  })
 
}
    
private _getFirstLecture(chapterId) {
  this.lectureService.getLectures(chapterId).subscribe(lectures=>{

   this.lectureId = lectures[0].id;


   
 
  })
}  


  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------
  ngOnInit(): void {
    this.coreConfig = JSON.parse(localStorage.getItem('config'));
    this.isRTL = this.coreConfig.app.appLanguage=='ar' &&  this.coreConfig.layout.type=='horizontal'; 
    
    // content header
        this._getCourse();
      
  }
}
