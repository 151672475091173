import { CoachingStudent } from "./coaching-student";
import { Package } from "./package";
import { Program } from "./program";
import { Role } from "./role";
export class User {
  id?: string;

  _id?: string;

  name?: string;
  email?: string;
  password?: string;
  photo?: string;
  mobile?: string;
  token?: string;
  role?: Role;
  dob?: string;
  gender?: string;
  address?: string;
  city?: string;
  country?: string;
  program?: Program;
  package?: Package;
  avatar?: string;
  isActive?: boolean;
  general?:Array<Object>;
  social?:Array<Object>;
  notification?:Array<Object>;
  coachingStudent?:CoachingStudent;
  emailConfirmed?: boolean;


}
