import { Component, OnInit, Input } from '@angular/core';
import { locale as english } from 'app/layout/page_translate/en';
import { locale as malay } from 'app/layout/page_translate/my';
import { locale as arabic } from 'app/layout/page_translate/ar';
import { CoreTranslationService } from '@core/services/translation.service';

// ContentHeader component interface
export interface ContentHeader {
  headerTitle: string;
  actionButton: boolean;

  breadcrumb?: {
    type?: string;
    links?: Array<{
      name?: string;
      isLink?: boolean;
      link?: string;
    }>;
  };
}

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html'
})
export class ContentHeaderComponent implements OnInit {
  // input variable
  @Input() contentHeader: ContentHeader;
  public coreConfig:any;
  public isRTL:boolean;
  constructor(private _coreTranslationService: CoreTranslationService) {
    this._coreTranslationService.translate(english,malay, arabic);
  }

  ngOnInit() {

    this.coreConfig = JSON.parse(localStorage.getItem('config'));
    this.isRTL = this.coreConfig.app.appLanguage=='ar' &&  this.coreConfig.layout.type=='horizontal'; 
    
  }
}
