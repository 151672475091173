export const locale = {
  lang: 'my',
  data: {
    auth:{
      please_sign_in:'Please sign-in to your account and start the adventure',
      welcome_to_classtive:'Welcome to My StudyIn! 👋',
      email_password_wrong:'E-mel atau kata laluan tidak betul',
      email:'Email',
      password:'Password',
      forget_password:'Forgot Password?',
      remember_me:'Remember Me',
      sign_in:'Sign in',
      sign_up:'Sign up',
      new_on_platform:'New on our platform?',
      create_an_account:'Create an account',
      email_required:'Email is required',
      email_must_valid:'Email must be a valid email address',
      password_required:'Password is required',
      email_already_exists:'Email already exists',
    },
    footer:{
      teach_on_courseel:'Mengajar di My StudyIn',
      aboutus:'Tentang kita',
      contactus:'Hubungi Kami',
      terms:'Syarat',
      privacy_policy:'Dasar privasi',
      help_and_support:'Bantuan dan sokongan',
      mystudyin:'My StudyIn',
      copyright:'HAK CIPTA',
      all_rights_reserved:'Hak cipta terpelihara',

    },
    card: {
      how_to_write_thesis: 'Bagaimana Menulis Tesis?!',
      card_description:'Ambil langkah pertama ke arah menghasilkan disertasi, tesis atau projek penyelidikan berkualiti tinggi melalui kursus latihan yang disesuaikan oleh jurulatih mahir.',
      get_started_now:'Mulakan sekarang',
      become_an_instructor:'Menjadi pengajar',
      become_an_instructor_desc:'Pengajar dari seluruh dunia mengajar berjuta-juta pelajar di My StudyIn. Kami menyediakan alatan dan kemahiran untuk mengajar perkara yang anda suka.',
      start_teaching_today:'Mula mengajar hari ini',
      transform_your_life:'Ubah hidup anda melalui pendidikan',
      transform_your_life_desc:'Pelajar di seluruh dunia sedang melancarkan kerjaya baharu, maju dalam bidang mereka, dan memperkayakan kehidupan mereka.',
      find_out_how:'Ketahui caranya',
    },
    course: {
      lets_start_learning: 'Mari mula belajar',
      search_for_anything:'Cari apa sahaja',
      what_you_learn:'Apa yang anda akan pelajari',
      created_by:'Dicipta oleh',
      last_updated:'Kemas kini terakhir',
      go_to_course_content:'Pergi ke kandungan kursus',
      course_content:'Kandungan kursus',
      course_materials:'Bahan bahagian',
      choose_prefered_method:'Pilih kaedah pilihan anda untuk mempelajari bahagian ini',
      exercise:'Senaman',
      optional:'Pilihan',
      optional_desc:'Senaman adalah pilihan yang membantu anda untuk mendapatkan lebih pemahaman',
      assignment:'Tugasan',
      minutes_to_complete:'Minit untuk disiapkan',
      mandatory:'Wajib',
      students:'pelajar',
      courses:'Kursus',
      rating:'Penilaian',
      k:'k',
      questions_comments:'Soalan & Komen',
      questions_comments_desc:'Navigasi ke soalan pelajar lain, komen atau tinggalkan komen atau soalan anda tentang subjek ini',
      comments:'Komen',
      subject_explanantion:'Penjelasan topik',
      email_instructor:'E-mel guru anda',
      email_instructor_desc:'Hubungi guru untuk pemahaman lanjut',
      email_Communication:'Komunikasi E-mel',
      download_slide_btn:'Klik di sini untuk memuat turun slaid',
      watch_video_btn:'Klik di sini untuk mula menonton video',
      this_course_includes:'Kursus ini merangkumi:',
      hours:'Jam',
      hours_video:'Jam video',
      articles:'artikel',
      requirements:'Keperluan',
      my_learning:'kursus saya',
      click_here_download_file:' Klik di sini untuk memuat turun fail',
      compressed:'Compressed file',
      docs:'Document',
      powerpoint:'Powerpoint',
      video:'Video',
      audio:'Audio',
      slides:'slaid',
      pages:'pages',
      pdf:'PDF',
      mints:'mints',

    },
    login:'Log masuk',
    signup:'Daftar',
    search:'Cari',
    featured:'Ditampilkan',
    free:'Percuma',
    admin:'Admin',
    student:'Pelajar',
    instructor:'Cikgu',
    home:'Home',
    account_setting:'Account Settings',
    
  }
};
