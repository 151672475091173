import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { environment } from 'environments/environment';
import { CoreCategoryMenu } from '@core/types/category-menu';
import { Category } from 'app/models/category';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {

  apiURLCategories = environment.apiUrl + 'categories';
  apiURLSubCategories = environment.apiUrl + 'sub-categories';

  public onCategoryEditChanged: BehaviorSubject<any>;
  public apiData: any;
  constructor(private http: HttpClient) { 
     // Set the defaults
     this.onCategoryEditChanged = new BehaviorSubject({});
  }

  getCategories(): Observable<Category[]> {

    return this.http.get<Category[]>(this.apiURLCategories);
  }

  
  getCategoriesList(): Observable<CoreCategoryMenu[]> {
    return this.http.get<CoreCategoryMenu[]>(`${this.apiURLCategories}/category-list`);
  }

  
  getCategoriesAdminList(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiURLCategories}/category-admin-list`);
  }

  
  /**
   * Get API Data
   */
   getCategory(categoryId: string): Observable<Category> {
    return this.http.get<Category>(`${this.apiURLCategories}/${categoryId}`);
  }
  
  /**
   * Get Subcategory Data
   */
   getSubCategory(categoryId: string): Observable<any> {
    return this.http.get<any>(`${this.apiURLSubCategories}/get-by-category/${categoryId}`);
  }


  createCategory(category: Category): Observable<Category> {
    return this.http.post<Category>(this.apiURLCategories, category);
  }


  private handleError(error: any) { 
    let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return throwError(error);
  }

  
  updateCategory(categoryData: FormData,categoryId:string): Observable<any> {
    if (categoryId) {
      return this.http.put(`${this.apiURLCategories}/${categoryId}`, categoryData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        catchError(this.handleError)); 
    }
    
    return this.http.post(`${this.apiURLCategories}`, categoryData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        catchError(this.handleError));
  }

  deleteCategory(categoryId: string): Observable<any> {
  
    return this.http.delete<any>(`${this.apiURLCategories}/${categoryId}`);
  }

}
