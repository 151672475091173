



<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- Card Advance -->
    <div class="row match-height">
      <!-- Congratulations Card -->
      <div class="col-12 col-md-9 col-lg-9">
        <div [class]="isRTL?'card card-congratulations-rtl':'card card-congratulations'">
          <div class="card-body text-center">
            <div class="content-header row ng-star-inserted">
              <div class="content-header-left col-md-9 col-12 mb-2">
                <div class="row breadcrumbs-top">
                  <div class="col-12 d-flex">
                    <div class="breadcrumb-wrapper d-flex"  *ngIf="!isRTL">
                      <ol class="breadcrumb">
                        <li class="breadcrumb-item ng-star-inserted">
                          <a
                            href=""
                            class="ng-star-inserted"
                            style="color: rgb(243 230 153)"
                            >{{ course.subCategory.category.title }}</a
                          >
                        </li>

                        <li class="breadcrumb-item ng-star-inserted">
                          <a
                            href=""
                            class="ng-star-inserted"
                            style="color: rgb(243 230 153)"
                            >{{ course.subCategory.title }}</a
                          >
                        </li>
                      </ol>
                    </div>

                    <div class="bread-crumb--wrapper" *ngIf="isRTL">
                      <ul class="bread-crumb">
                        <li>
                          <a href="">
                            <span class="bread-crumb__title">الرياضيات</span>
                          </a>
                        </li>
                        <li>
                          <a href="" >
                            <i data-feather="chevron-left" class="text-muted"></i>

                            <span class="bread-crumb__title">مدخل إلى الجمع والطرح</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div [ngClass]="isRTL?'text-right':'text-left'">
              <h1 class="mb-1 text-white">{{ course.title }}</h1>
              <p class="card-text w-75">
                {{ course.description }}
              </p>

              <p *ngIf="!course.isCoaching"  class="card-text w-75">
                {{ 'course.created_by' | translate }} 
                <strong
                  ><a style="text-decoration: underline">{{
                    course.instructor.name
                  }}</a></strong
                >
              </p>

              <div class="rating rating-sm">
                <h6 style="color: #e8850d" class="item-rating">
                  <span 
                  [ngClass]="isRTL?'font-rtl':''" 
                  
                  style="color: white; font-weight: 400"
                    >{{ 'course.last_updated' | translate }} {{course.updatedAt  | date:'MMMM d, y' }}</span
                  >&nbsp; &nbsp;

                  <!-- {{ course.rating }} -->
                  <ngb-rating
                  *ngIf="!course.isCoaching" 
                    [rate]="course.rating"
                    [readonly]="true"
                    [max]="5"
                    class="outline-none "
                  >
                    <ng-template let-fill="fill" let-index="index"
                      ><span
                        style="color: rgb(255, 200, 0)"
                        class="fa fa-star-o mr-25"
                        [class.fa-star]="fill === 100"
                      ></span
                    ></ng-template>
                  </ngb-rating>
                </h6>
              </div>
              <br>
              <p class="font-medium-2 font-weight-bold "> {{'course.this_course_includes'|translate}}</p>
              <div class="d-flex flex-wrap my-50">
                
                <div class="user-info-title">
                  <i data-feather="video" [class]="isRTL?'mr-1 icon-padding-rtl yellow-color':'ml-1 icon-padding yellow-color'"></i>
                </div>
                <p class="card-text mb-0"> {{'course.video'|translate}}</p>
             
                <div class="user-info-title">
                  <i data-feather="twitch"  [class]="isRTL?'mr-1 icon-padding-rtl yellow-color':'ml-1 icon-padding yellow-color'"></i>
                </div>
                <p class="card-text mb-0">{{'course.slides'|translate}}</p>
            
                <div class="user-info-title">
                  <i data-feather="file"  [class]="isRTL?'mr-1 icon-padding-rtl yellow-color':'ml-1 icon-padding yellow-color'"></i>
                </div>
                <p class="card-text mb-0">{{'course.articles'|translate}}</p>

                <div class="user-info-title">
                  <i data-feather="volume-2"  [class]="isRTL?'mr-1 icon-padding-rtl yellow-color':'ml-1 icon-padding yellow-color'"></i>
                </div>
                <p class="card-text mb-0">{{'course.audio'|translate}}</p>


              </div>
           

            </div>
          </div>
        </div>
      </div>

      <!-- Developer Meetup Card -->
      <div class="col-lg-3 col-md-3 col-12">
        <div class="card card-developer-meetup">
          <div class="bg-light-primary rounded-top text-center">
            <img class="card-img" [src]="course.coverPhoto" />
          </div>

          
          <div class="card-body">

              <div class="user-info-wrapper ">
            
                <i data-feather="alert-circle" class="font-medium-3 mr-1"></i>
             
              <span *ngIf="!isRTL" class="font-weight-bold"
                >You joined this course on {{course.createdAt  | date:'MMMM d, y' }}
                </span>
                <span *ngIf="isRTL" class="font-weight-bold"
                > لقد اشتريت هذا الدرس  في يوليو. 15 ، 2022
                </span>
             
              </div>
         <br>  <br>
            
            <!-- <p class="card-text">
              <i data-feather="alert-circle" class="font-medium-3 mr-1"></i>
             
              <span *ngIf="!isRTL" class="font-weight-bold"
                >You purchased this course on Jul. 15, 2022
                </span>
                <span *ngIf="isRTL" class="font-weight-bold"
                > لقد اشتريت هذا الدرس  في يوليو. 15 ، 2022
                </span>
               

            </p> -->

            <div class="text-center">
              <button
                (click)="showCourseLecture()"
                
                type="button"
                class="btn btn-relief-primary"
                rippleEffect
              >
                {{'course.go_to_course_content'|translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--/ Developer Meetup Card -->

    <div class="row match-height">
      <!-- User Timeline Card -->
      <!-- / Basic multiple Column Form section start -->

      <div class="col-12 col-md-9 col-lg-9">
        <div class="card card-user-timeline">
          
          <div *ngIf="course.learns.length>0" class="card-header">
            <h4 class="card-title">
              {{ "course.what_you_learn" | translate }}
            </h4>
            
            <!-- What you will learn -->
            <div class="card-body">
              
              <div class="row">
                <!-- Row -->
                <div class="col-md-6 col-12"  *ngFor="let learn of course.learns">
                  <div class="form-group">
                    <i
                      *ngIf="isRTL"
                      data-feather="check"
                      class="'font-medium-3 mr-1 mr-1-rtl"
                    ></i>
                    <i
                      *ngIf="!isRTL"
                      data-feather="check"
                      class="'font-medium-3 mr-1"
                    ></i>

                    <span class="font-weight-regular"
                      >{{learn.learnItem}}</span
                    >
                  </div>
                </div>

                <!-- End Row -->

              </div>
            </div>
       <!-- End What you learn -->

          </div>
      
          <div *ngIf="course.requirements.length>0"  class="card-header">
            <h4 class="card-title">
              {{ "course.requirements" | translate }}
            </h4>
            <!-- What you will learn -->
            <div class="card-body">
              
              <div class="row">
                <!-- Row -->
                <div class="col-md-6 col-12"  *ngFor="let requirement of course.requirements">
                  <div class="form-group">
                    <i
                      *ngIf="isRTL"
                      data-feather="check"
                      class="'font-medium-3 mr-1 mr-1-rtl"
                    ></i>
                    <i
                      *ngIf="!isRTL"
                      data-feather="check"
                      class="'font-medium-3 mr-1"
                    ></i>

                    <span class="font-weight-regular"
                      >{{requirement.reqItem}}</span
                    >
                  </div>
                </div>

                <!-- End Row -->

              </div>
            </div>
       <!-- End What you learn -->

          </div>



        </div>
      </div>

      <!-- Basic multiple Column Form section end -->

      <!--/ User Timeline Card -->
      <!-- Medal Card -->
      <div *ngIf="!course.isCoaching" class="col-lg-3 col-md-6 col-12">
        <div class="card card-profile">
          <div class="card card-congratulations" style="height: 1%"></div>
          <div class="card-body">
            <div class="profile-image-wrapper">
              <div class="profile-image">
                <div class="avatar">
                  <img
                    src="{{ course.instructor.avatar }}"
                    alt="Profile Picture"
                  />
                </div>
              </div>
            </div>
            <h3>{{ course.instructor.name }}</h3>
            <h6 class="text-muted">
              <country-name 
              [countryCode]="course.instructor.general.country"
             
              [isRTL]="isRTL"
              ></country-name></h6>
      
            <div
              class="font-weight-regular badge badge-light-primary profile-badge"
              style="white-space: normal; line-height: 1.5"
            >
            {{ course.instructor.general.jobTitle }}
            </div>
            <hr class="mb-2" />
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h6 class="text-muted font-weight-bolder">{{'course.students'|translate}}</h6>
                <h3 class="mb-0">10.3{{'course.k'|translate}}</h3>
              </div>
              <div>
                <h6 class="text-muted font-weight-bolder">{{'course.courses'|translate}}</h6>
                <h3 class="mb-0">{{ course.instructor.instructor.coursesCount }}</h3>
              </div>
              <div>
                <h6 class="text-muted font-weight-bolder">{{'course.rating'|translate}}</h6>
                <h3 class="mb-0">
                  <span
                    style="color: rgb(255, 200, 0)"
                    class="fa fa-star-o mr-25"
                    >&nbsp;<span class="mb-0 h3" style="font-family: system-ui"
                      >4.5</span
                    ></span
                  >
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/ Medal Card -->
  </div>
</div>
