import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AccountSettingsService } from 'app/services/account-settings.service';

@Component({
  selector: 'country-name',
  templateUrl: './country-name.component.html',
  encapsulation: ViewEncapsulation.None
})
export class CountryNameComponent implements OnInit {
  // Input Decorotor
  @Input() countryCode:string;
  @Input() isRTL:boolean;
  country:string;
  constructor(
    private _accountSettingsService: AccountSettingsService,
  ) {}


  ngOnInit(): void {
      this.country = this._accountSettingsService.getCountryByLang(this.countryCode,this.isRTL?'ar':'en');
  }



}
