
<div *ngIf="lecture.assets?.length>0" class="card-body">

  <ul class="timeline ml-50 mb-0">
    <li class="timeline-item">
      <span class="timeline-point timeline-point-indicator"></span>
      <div class="timeline-event">
       
        <div *ngIf="assets.lecture_explenation?.length>0" class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
          <!-- <h5>{{ "course.choose_prefered_method" | translate }}</h5> -->
          <h5>Course Content</h5>
          <span class="timeline-event-time mr-1">
            {{ "course.subject_explanantion" | translate }}</span>
        </div>


        <div class="row d-flex align-items-center">
          <div class="col-md-12">
            <!-- Accordion start -->
            <section  *ngIf="assets.lecture_explenation?.length>0"  id="collapsible">
              <div class="row">
                <div class="col-sm-12">
                  <core-card-snippet [snippetCode]="">
                    <div [ngClass]="isRTL? 'collapse-icon-right': 'collapse-icon'">
                      <ngb-accordion [closeOthers]="true"   *ngFor="let lecture_explenation of assets.lecture_explenation">
                        
        
                 <!-- Video -->
                 <ngb-panel *ngIf="lecture_explenation.fileType =='video'"  id="accordion-2">
                  <ng-template ngbPanelTitle>
                    <div class="media align-items-center">
                      <img [ngClass]="isRTL?'ml-1':'mr-1'" [src]="lecture_explenation.video_source == 'youtube'?'assets/images/icons/youtube.png':'assets/images/icons/video.png'" alt="data.json"
                        height="23" />
                      <h6 class="media-body mb-0">{{ (lecture_explenation.description.length>40)? (lecture_explenation.description | slice:0:40)+'..':(lecture_explenation.description) }}
                      </h6>
                      <p class="timeline-event-time mr-1 media-body mb-0">
                        {{lecture_explenation.hours?lecture_explenation.hours+' hours,':''}}  {{ lecture_explenation.minutes?lecture_explenation.minutes+' minutes,':''}}  {{lecture_explenation.seconds?lecture_explenation.seconds+' seconds':''}} 
                      </p>

                    </div>
                  </ng-template>

                  <ng-template ngbPanelContent>
                    <p *ngIf="!isRTL" class="card-text" style="text-align:left;">
                      {{lecture_explenation.description}} </p>

                    <p *ngIf="isRTL" class="card-text" style="text-align:right;">
                      {{lecture_explenation.description}} </p>



                    <div id="watch-video-panel" class="transparent-background">
                      <div class="transparent-card">
                    
                        <div class="transparent-center-btn">

                          <button (click)="startWatchingVideo()" type="button" rippleeffect=""
                            class="btn btn-primary waves-effect waves-float waves-light">
                            {{ 'course.watch_video_btn' | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
               
                    <div 
                    plyr
                    (plyrPlay)="played(lecture_explenation._id)"
                    plyrTitle="Video"
                    [plyrPlaysInline]="true"
                    [plyrPoster]="course.coverPhoto"
                    [plyrSources]="lecture_explenation.uploadedFile"
                    [plyrOptions]="plyrOptions"
                  ></div>

                  </ng-template>
                </ngb-panel>

       <!-- Youtube -->
       <ngb-panel *ngIf="lecture_explenation.fileType =='youtube'"  id="accordion-2">
        <ng-template ngbPanelTitle>
          <div class="media align-items-center">
            <img [ngClass]="isRTL?'ml-1':'mr-1'" [src]="'assets/images/icons/youtube.png'" alt="data.json"
              height="23" />
            <h6 class="media-body mb-0">{{ (lecture_explenation.description.length>40)? (lecture_explenation.description | slice:0:40)+'..':(lecture_explenation.description) }}
            </h6>
            <p class="timeline-event-time mr-1 media-body mb-0">

              {{lecture_explenation.hours?lecture_explenation.hours+' hours,':''}}  {{ lecture_explenation.minutes?lecture_explenation.minutes+' minutes,':''}}  {{lecture_explenation.seconds?lecture_explenation.seconds+' seconds':''}} 


            </p>

          </div>
        </ng-template>

        <ng-template ngbPanelContent>
          <div id="watch-video-panel" class="transparent-background">
            <div class="transparent-card">
              <p *ngIf="!isRTL" class="card-text" style="text-align:left;">
                {{lecture_explenation.description}} </p>
              <p *ngIf="isRTL" class="card-text" style="text-align:right;">
                {{lecture_explenation.description}} </p>
              <div class="transparent-center-btn">

                <button (click)="startWatchingVideo()" type="button" rippleeffect=""
                  class="btn btn-primary waves-effect waves-float waves-light">
                  {{ 'course.watch_video_btn' | translate }}
                </button>
              </div>
            </div>
          </div>
          <div>
            <iframe  [src]="'https://www.youtube.com/embed/'+lecture_explenation.uploadedFile | safe: 'resourceUrl'"
              class="w-100 rounded border-0 height-350 mb-50"></iframe>
          </div>
      

        </ng-template>
      </ngb-panel>

<!-- Audio -->
                <ngb-panel *ngIf="lecture_explenation.fileType =='audio'"  id="accordion-3">
                  <ng-template ngbPanelTitle>
                    <div class="media align-items-center">
                      <img [ngClass]="isRTL?'ml-1':'mr-1'" src="assets/images/icons/audio.png" alt="data.json"
                        height="35" />
                      <h6 class="media-body mb-0">
                        {{ (lecture_explenation.description.length>40)? (lecture_explenation.description | slice:0:40)+'..':(lecture_explenation.description) }}
                      </h6>
                      <p class="timeline-event-time mr-1 media-body mb-0">
                        {{lecture_explenation.hours?lecture_explenation.hours+' hours,':''}}  {{ lecture_explenation.minutes?lecture_explenation.minutes+' minutes,':''}}  {{lecture_explenation.seconds?lecture_explenation.seconds+' seconds':''}} 
                      </p>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <p *ngIf="!isRTL" class="card-text grey-font" style="text-align:left;">
                      {{lecture_explenation.description}}</p>
                    <p *ngIf="isRTL" class="card-text grey-font" style="text-align:right;">
                      {{lecture_explenation.description}} </p>
                      <!-- plyr audio -->
<div plyr plyrTitle="Audio" plyrType="audio"  [plyrSources]="lecture_explenation.uploadedFile"></div>
<!--/ plyr audio -->

                  </ng-template>
                </ngb-panel>                
                        
                        
                        <!-- Pdf   -->
                         <ngb-panel *ngIf="lecture_explenation.uploadedFileType =='application/pdf'" id="accordion-1">
                          <ng-template ngbPanelTitle>
                            <div class="media align-items-center">
                              <img [ngClass]="isRTL ? 'ml-1' : 'mr-1'" src="assets/images/icons/pdf.png"
                                alt="data.json" height="25" />
                              <h6 class="media-body mb-0">
                                {{ (lecture_explenation.description.length>40)? (lecture_explenation.description | slice:0:40)+'..':(lecture_explenation.description) }}

                              </h6>
                              <p class="timeline-event-time mr-1 media-body mb-0">
                                {{lecture_explenation.pageCount}} {{'course.pages'|translate}}
                              </p>
                            </div>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="transparent-card-small">
                              <p *ngIf="!isRTL" class="card-text grey-font" style="text-align:left;">
                                {{lecture_explenation.description}}</p>
                              <p *ngIf="isRTL" class="card-text grey-font" style="text-align:right;">
                                {{lecture_explenation.description}} </p>
                              <div class="transparent-center-btn-small">
                                <a href="{{lecture_explenation.uploadedFile}}" target="_blank" type="button" class="btn btn-primary waves-effect waves-float waves-light">
                                  {{ 'course.click_here_download_file' | translate }}
                                </a>
                              </div>
                            </div>
                          </ng-template>
                        </ngb-panel>
    
                        <!-- End Pdf  -->      
                     <!-- Word Docs  -->
                     <ngb-panel *ngIf="lecture_explenation.uploadedFileType =='application/vnd.openxmlformats-officedocument.wordprocessingml.document'" id="accordion-1">
                      <ng-template ngbPanelTitle>
                        <div class="media align-items-center">
                          <img [ngClass]="isRTL ? 'ml-1' : 'mr-1'" src="assets/images/icons/docs.png"
                            alt="data.json" height="25" />
                          <h6 class="media-body mb-0">
                            {{ (lecture_explenation.description.length>40)? (lecture_explenation.description | slice:0:40)+'..':(lecture_explenation.description) }}

                          </h6>
                          <p class="timeline-event-time mr-1 media-body mb-0">
                            {{lecture_explenation.pageCount}} {{'course.pages'|translate}}
                          </p>
                        </div>
                      </ng-template>
                      <ng-template ngbPanelContent>
                        <div class="transparent-card-small">
                          <p *ngIf="!isRTL" class="card-text grey-font" style="text-align:left;">
                            {{lecture_explenation.description}}</p>
                          <p *ngIf="isRTL" class="card-text grey-font" style="text-align:right;">
                            {{lecture_explenation.description}} </p>
                          <div class="transparent-center-btn-small">
                            <a href="{{lecture_explenation.uploadedFile}}" target="_blank" type="button" class="btn btn-primary waves-effect waves-float waves-light">
                              {{ 'course.click_here_download_file' | translate }}
                            </a>
                          </div>
                        </div>
                      </ng-template>
                    </ngb-panel>

                    <!-- End Word Docs  -->       


                        <!-- Powerpoint Slides  -->
                        <ngb-panel *ngIf="lecture_explenation.uploadedFileType =='application/vnd.openxmlformats-officedocument.presentationml.presentation'" id="accordion-1">
                          <ng-template ngbPanelTitle>
                            <div class="media align-items-center">
                              <img [ngClass]="isRTL ? 'ml-1' : 'mr-1'" src="assets/images/icons/powerpoint.png"
                                alt="data.json" height="23" />
                              <h6 class="media-body mb-0">
                               {{ (lecture_explenation.description.length>40)? (lecture_explenation.description | slice:0:40)+'..':(lecture_explenation.description) }}

                              </h6>
                              <p class="timeline-event-time mr-1 media-body mb-0">
                                {{lecture_explenation.pageCount}} {{'course.slides'|translate}}
                              </p>
                            </div>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="transparent-card-small">
                              <p *ngIf="!isRTL" class="card-text grey-font" style="text-align:left;">
                                {{lecture_explenation.description}}</p>
                              <p *ngIf="isRTL" class="card-text grey-font" style="text-align:right;">
                                {{lecture_explenation.description}} </p>
                              <div class="transparent-center-btn-small">
                                <a href="{{lecture_explenation.uploadedFile}}" target="_blank" type="button" class="btn btn-primary waves-effect waves-float waves-light">
                                  {{ 'course.click_here_download_file' | translate }}
                                </a>
                              </div>
                            </div>
                          </ng-template>
                        </ngb-panel>

                        <!--  External  -->
                        <ngb-panel *ngIf="lecture_explenation.fileType=='external'" id="accordion-1">
                          <ng-template ngbPanelTitle>
                            <div class="media align-items-center">
                              <img [ngClass]="isRTL ? 'ml-1' : 'mr-1'" src="assets/images/icons/external.png"
                                alt="data.json" height="23" />
                              <h6 class="media-body mb-0">
                               {{ (lecture_explenation.description.length>40)? (lecture_explenation.description | slice:0:40)+'..':(lecture_explenation.description) }}

                              </h6>
                            
                            </div>
                          </ng-template>
                          <ng-template ngbPanelContent>
                            <div class="transparent-card-small">
                              <p *ngIf="!isRTL" class="card-text grey-font" style="text-align:left;">
                                {{lecture_explenation.description}}</p>
                              <p *ngIf="isRTL" class="card-text grey-font" style="text-align:right;">
                                {{lecture_explenation.description}} </p>
                              <div class="transparent-center-btn-small">
                                <a href="{{lecture_explenation.uploadedFile}}" target="_blank" type="button" class="btn btn-primary waves-effect waves-float waves-light">
                                Click here to open the link
                                </a>
                              </div>
                            </div>
                          </ng-template>
                        </ngb-panel>

                        <!-- End Powerpoint Slides  -->

                           <!-- ZipFile -->
                           <ngb-panel *ngIf="lecture_explenation.fileType =='downloadable'" id="accordion-1">
                            <ng-template ngbPanelTitle>
                              <div class="media align-items-center">
                                <img [ngClass]="isRTL ? 'ml-1' : 'mr-1'" src="assets/images/icons/compressed.png"
                                  alt="data.json" height="23" />
                                <h6 class="media-body mb-0">
                                  {{ (lecture_explenation.description.length>40)? (lecture_explenation.description | slice:0:40)+'..':(lecture_explenation.description) }}

                                </h6>
                               
                              </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                              <div class="transparent-card-small">
                                <p *ngIf="!isRTL" class="card-text grey-font" style="text-align:left;">
                                  {{lecture_explenation.description}}
                                </p>
                                <p *ngIf="isRTL" class="card-text grey-font" style="text-align:right;">
                                {{lecture_explenation.description}}
                                </p>
                                <div class="transparent-center-btn-small">
                                  <a href="{{lecture_explenation.uploadedFile}}" target="_blank" type="button" class="btn btn-primary waves-effect waves-float waves-light">
                                    {{ 'course.click_here_download_file' | translate }}
                                  </a>
                                </div>
                              </div>
                            </ng-template>
                          </ngb-panel>
  
                          <!-- End  zip  -->
                      </ngb-accordion>
                    </div>
                  </core-card-snippet>
                </div>
              </div>
            </section>
            <!-- Collapse end -->
          </div>
        </div>
      </div>
    </li>
    <!-- End Explation -->
<!-- QUIZZ -->
    <!-- <hr class="invoice-spacing" />
    <li class="timeline-item">
      <span class="timeline-point timeline-point-warning timeline-point-indicator"></span>
      <div class="timeline-event">
        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
          <h3>Quiz</h3>
       
            <span class="timeline-event-time mr-1">Quiz is optional that helps you to gain more understanding

            </span>
        </div>
        <br />
        <p>{{ "course.optional_desc" | translate }}</p>
      </div>
    </li> -->
    <hr class="invoice-spacing"/>
    <li class="timeline-item" *ngIf="lecture.assignments?.length>0">
      <span class="timeline-point timeline-point-info timeline-point-indicator"></span>
      <div class="timeline-event">
        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
          <h3>{{ "course.assignment" | translate }}</h3>
        </div>
        <br />
        <div class="row d-flex align-items-center">
          <div class="col-md-12">
            <!-- Accordion start -->
            <section id="collapsible">
              <div class="row">
                <div class="col-sm-12">
                  <core-card-snippet [snippetCode]="">
                    <div [ngClass]="isRTL? 'collapse-icon-right': 'collapse-icon'">
                      <ngb-accordion [closeOthers]="true"   *ngFor="let assignment of lecture.assignments let i = index;">
                     <!-- Assignment files  -->
                     <ngb-panel id="accordion-1">
                      
                      <ng-template ngbPanelTitle>
                        
                         

                        <div class="media align-items-center">
                        
                          <h6 class="media-body mb-0">
                            {{ "course.assignment" | translate }} {{i+1}}
                          </h6>

                          <span *ngIf="assignment.isRequired" class="timeline-event-time ml-2 mr-2">{{ "course.mandatory" | translate }}
                          <span class="font-weight-bold  ml-1 mr-1" style="color: red">*</span></span>
                   
                          <span *ngIf="!assignment.isRequired" class="timeline-event-time ml-2 mr-2">{{ "course.optional" | translate }}
                          </span>

                          <p *ngIf="assignment.expiredAt" class="timeline-event-time mr-1 media-body mb-0">Deadline: {{assignment.expiredAt | date:'EEEE, MMMM d, y'}}</p>
                          <p *ngIf="!assignment.expiredAt" class="timeline-event-time mr-1 media-body mb-0">&nbsp;</p>

                        </div>



                        
                       
                       
                      </ng-template>

                      
                      <ng-template ngbPanelContent>
                        <student-assignment-form 
                        [lecture]="lecture"
                        [assignment]="assignment"
                        [currentStudent]="currentStudent"
                        [isRTL]="isRTL"
                        >
                        </student-assignment-form>
                        
                   
                      </ng-template>
                    </ngb-panel>
                      </ngb-accordion>
                    </div>
                  </core-card-snippet>
                </div>
              </div>
            </section>
            <!-- Collapse end -->
          </div>
        </div>
      </div>
    </li>
    <!-- <hr class="invoice-spacing" />
    <li class="timeline-item">
      <span class="timeline-point timeline-point-danger timeline-point-indicator"></span>
      <div class="timeline-event">
        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
          <h3>{{ "course.questions_comments" | translate }}</h3>
          <span class="timeline-event-time mr-1">{{
            "course.comments" | translate
            }}</span>
        </div>
        <p class="mb-0">
          {{ "course.questions_comments_desc" | translate }}
        </p>
      </div>
    </li> -->

    <!-- <hr class="invoice-spacing" />
    <li class="timeline-item">
      <span class="timeline-point timeline-point-success timeline-point-indicator"></span>

      <div class="timeline-event">
        <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
          <h3>{{ "course.email_instructor" | translate }}</h3>
          <span class="timeline-event-time mr-1">{{
            "course.email_Communication" | translate
            }}</span>
        </div>
        <p class="mb-0">
          {{ "course.email_instructor_desc" | translate }}
        </p>
      </div>
    </li> -->
  </ul>
</div>