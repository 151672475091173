<div class="content-wrapper container-xxl overflow-hidden p-0">



  <div class="content-detached content-right">
       <!-- E-commerce Head Section Starts -->
       <!-- <div style="margin-top: 1.5rem !important;">
        <h2 class="card-title text-body">{{ 'course.lets_start_learning' | translate }}</h2>
      </div> -->

      <!-- <section id="ecommerce-searchbar" class="ecommerce-searchbar">
        <div class="row mt-1">
          <div class="col-sm-8">
            <div class="input-group input-group-merge">
              <input
                type="text"
                class="form-control search-product"
                id="shop-search"
                placeholder="{{ 'course.search_for_anything' | translate }}"
                aria-label="Search..."
                aria-describedby="shop-search"
                [(ngModel)]="searchText"
              />
              <div class="input-group-append">
                <span class="input-group-text"><i data-feather="search" class="text-muted"></i></span>
              </div>
            </div>
          </div>
       
          <div class="col-sm-4">
            <div class="ecommerce-header-items">
          
              <div class="view-options d-flex">
                <div class="btn-group dropdown-sort mr-1" ngbDropdown>
                  <button
                    ngbDropdownToggle
                    type="button"
                    class="btn btn-outline-primary ml-1"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    rippleEffect
                  >
                    <span class="active-sorting">{{ 'featured' | translate }}</span>
                  </button>
                  <div ngbDropdownMenu>
                    <a ngbDropdownItem href="javascript:void(0);" (click)="sortcourse('featured')">Featured</a>
                    <a ngbDropdownItem href="javascript:void(0);" (click)="sortcourse('price-asc')">Lowest</a>
                    <a ngbDropdownItem href="javascript:void(0);" (click)="sortcourse('price-desc')">Highest</a>
                  </div> 
                </div>
                <div 
               
                [ngClass]="coreConfig.app.appLanguage=='ar' && coreConfig.layout.type=='horizontal' ? 
                'btn-group btn-group-toggle ltr-layout' : 'btn-group btn-group-toggle'"  
                data-toggle="buttons">
                  <label
                    class="btn btn-icon btn-outline-primary view-btn grid-view-btn"
                    [class.active]="gridViewRef === true"
                  >
                    <input type="radio" name="radio_options" id="radio_option1" (click)="gridView()" />
                    <i data-feather="grid" class="font-medium-3"></i>
                  </label>
                  <label
                    class="btn btn-icon btn-outline-primary view-btn list-view-btn"
                    [class.active]="gridViewRef !== true"
                  >
                    <input type="radio" name="radio_options" id="radio_option2" (click)="listView()" />
                    <i data-feather="list" class="font-medium-3"></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      
      
      </section> -->

      <!-- E-commerce courses Starts -->
      <section id="courses" [ngClass]="gridViewRef === true ? 'grid-view' : 'list-view'">
        <course-item
          class="card ecommerce-card"
          [course]="course"
          *ngFor="
            let course of courses
              
              | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize
          "
        ></course-item>
      </section>
      <!-- E-commerce courses Ends -->

      <!-- E-commerce Pagination Starts -->
      <section id="ecommerce-pagination">
        <div class="row">
          <div class="col-sm-12">
            <div class="d-flex justify-content-center">
              <ngb-pagination
                [collectionSize]="courses?.length"
                [(page)]="page"
                [maxSize]="5"
                [rotate]="true"
                [ellipses]="false"
                [boundaryLinks]="true"
              >
                <ng-template ngbPaginationPrevious
                  ><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'" [size]="18"></span>
                </ng-template>
                <ng-template ngbPaginationFirst
                  ><span [data-feather]="'chevrons-left'" [class]="'font-weight-bolder'" [size]="18"></span>
                </ng-template>
                <ng-template ngbPaginationNext
                  ><span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'" [size]="18"></span
                ></ng-template>
                <ng-template ngbPaginationLast
                  ><span [data-feather]="'chevrons-right'" [class]="'font-weight-bolder'" [size]="18"></span>
                </ng-template>
              </ngb-pagination>
            </div>
          </div>
        </div>
      </section>
      <!-- E-commerce Pagination Ends -->
   
  </div>
</div>
