import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu_admin: CoreMenu[] = [
  {
    id: 'homepage',
    title: 'Homepage',
    translate: 'MENU.HOME.HOME',
    type: 'item',
    icon: 'home',
    url: 'home'
  },
  {
    id: 'categories',
    title: 'Categoires',
    translate: 'MENU.ADMIN.CATEGORIES',
    type: 'item',
    role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
    icon: 'grid',
    url: 'admin/categories-list'
  },
  {
    id: 'levels',
    title: 'Levels',
    translate: 'MENU.ADMIN.levels',
    type: 'item',
    role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
    icon: 'zap',
    url: 'admin/levels-list'
  },


  {
    id: 'packages',
    title: 'Packages',
    translate: 'MENU.ADMIN.packages',
    type: 'item',
    role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
    icon: 'package',
    url: 'admin/packages-list'
  },

  {
    id: 'faculties',
    title: 'Faculties',
    translate: 'MENU.ADMIN.faculties',
    type: 'item',
    role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
    icon: 'columns',
    url: 'admin/faculties-list'
  },

  {
    id: 'students',
    title: 'Students',
    translate: 'MENU.ADMIN.students',
    type: 'item',
    role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
    icon: 'user',
    url: 'admin/students-list'
  },

  {
    id: 'coachers',
    title: 'Instructors',
    translate: 'MENU.ADMIN.instructors',
    type: 'item',
    role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
    icon: 'user-plus',
    url: 'admin/coachers-list'
  },

  {
    id: 'coachingCourses',
    title: 'Courses',
    translate: 'MENU.ADMIN.courses',
    type: 'item',
    role: ['Admin'], //? To set multiple role: ['Admin', 'Client']
    icon: 'book-open',
    url: 'admin/coaching-courses-list'
  },
 
];
