export const locale = {
  lang: 'en',
  data: {
    auth:{
      please_sign_in:'Please sign-in to your account and start the adventure',
      welcome_to_classtive:'Welcome to My StudyIn! 👋',
      email_password_wrong:'Email or password is incorrect',
      email:'Email',
      password:'Password',
      forget_password:'Forgot Password?',
      remember_me:'Remember Me',
      sign_in:'Sign in',
      sign_up:'Sign up',
      new_on_platform:'New on our platform?',
      create_an_account:'Create an account',
      email_required:'Email is required',
      email_must_valid:'Email must be a valid email address',
      password_required:'Password is required',
      email_already_exists:'Email already exists',
    },
    footer:{
      teach_on_courseel:'Teach on My StudyIn',
      aboutus:'About us',
      contactus:'Contact us',
      terms:'Terms',
      privacy_policy:'Privacy policy',
      help_and_support:'Help and Support',
      mystudyin:'My StudyIn',
      copyright:'COPYRIGHT',
      all_rights_reserved:'All rights Reserved',

    },
    card: {
      how_to_write_thesis: 'How to Write a Thesis?!',
      card_description:'Take the first step towardscrafting a high-quality dissertation,thesis or research project through customized training courses by skilled trainers.',
      get_started_now:'Get Started Now',
      become_an_instructor:'Become an instructor',
      become_an_instructor_desc:'Instructors from around the world teach millions of students on My StudyIn. We provide the tools and skills to teach what you love.',
      start_teaching_today:'Start teaching today',
      transform_your_life:'Transform your life through education',
      transform_your_life_desc:'Learners around the world are launching new careers, advancing in their fields, and enriching their lives.',
      find_out_how:'Find out how',
    },
    course: {
      lets_start_learning: 'Let\'s start learning',
      search_for_anything:'Search for anything',
      what_you_learn:'What you\'ll learn',
      created_by:'Created by',
      last_updated:'Last updated',
      go_to_course_content:'Go to course content',
      course_content:'Course content',
      course_materials:'Section materials',
      choose_prefered_method:'Choose your preferred method to learn this section',
      exercise:'Exercise',
      optional:'Optional',
      optional_desc:'Exercise is optional that helps you to gain more understanding',
      assignment:'Assignment',
      minutes_to_complete:'Minutes to complete',
      mandatory:'Mandatory',
      students:'Students',
      courses:'Courses',
      rating:'Rating',
      k:'k',
      questions_comments:'Questions & Comments',
      questions_comments_desc:'Navigate to the other students questions, comments or leave your comment or question about this subject',
      comments:'Comments',
      subject_explanantion:'Explanation of the topic',
      email_instructor:'Email your instructor',
      email_instructor_desc:'Contact the instructor for further understanding',
      email_Communication:'Email Communication',
      download_slide_btn:'Click here to download the slides',
      watch_video_btn:'Click here to start watching the video',
      this_course_includes:'This course includes:',
      
      hours_video:'hours video',
      articles:'articles',
      requirements:'Requirements',
      my_learning:'My Learning',
      click_here_download_file:' Click here to download the file',
      compressed:'Compressed file',
      docs:'Document',
      pdf:'PDF',
      powerpoint:'Powerpoint',
      video:'Video',
      audio:'Audio',
      slides:'slides',
      pages:'pages',
      mints:'mints',

      
    },
    login:'Log in',
    signup:'Sign up',
    search:'Search',
    featured:'Featured',
    free:'Free',
    admin:'Admin',
    student:'Student',
    instructor:'Instructor',
    home:'Home',
    account_setting:'Account Settings',
    
    
    
  }
};
