<section id="collapsible">
  <div class="row">
    <div class="col-sm-12">
      <core-card-snippet [snippetCode]="">
        <div [ngClass]="isRTL ? 'collapse-icon-right' : 'collapse-icon'">
          <h3 class="card-body font-weight-bold">
            {{ "course.course_content" | translate }}
          </h3>
          <ngb-accordion
            [closeOthers]="true"
            *ngFor="let chapter of chapters"
            [activeIds]="'accordion-open'"
          >
            <ngb-panel
              [id]="chapter._id == chapterId ? 'accordion-open' : 'accordion-close'"
            >
              <ng-template ngbPanelTitle>
                <span
                  [ngClass]="isRTL? 'lead collapse-title span-right': 'lead collapse-title' "
                  >{{ chapter.title }}
                </span>
              </ng-template>

              <ng-template ngbPanelContent>
                <ul
                  ngbNav
                  #navVertical="ngbNav"
                  [class]="
                    isRTL
                      ? 'nav nav-pills flex-column nav-left padding-right-rtl'
                      : 'nav nav-pills flex-column nav-left '
                  "
                >
                  <li ngbNavItem *ngFor="let lecture of chapter.lectures">
                    <a
                      [class]="
                        lectureId == lecture._id
                          ? 'nav-link d-flex py-75 active'
                          : 'nav-link d-flex py-75'
                      "
                      (click)="showLectureAsset(lecture._id)"
                      role="tab"
                    >
                      <span class="font-weight content-item">
                        {{lecture.title}}</span>
                     
                      <div *ngIf="lecture.assignments_count > 3" class="circle" [ngClass]="isRTL ? 'success-task-rtl' : 'success-task'">
                        <div class="checkmark"></div>
                      </div>
                  

                      <div *ngIf="lecture.assignments_count > 0 && lecture.assignments_count < 3" class="warning-circle"  [ngClass]="isRTL?'success-task-rtl':'success-task'">
                      <span class="warning-task">{{lecture.assignments_count}}</span>
                      </div>
                    </a>
                  </li>
                </ul>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </core-card-snippet>
    </div>
  </div>
</section>