import { Component, OnInit,Input, SimpleChanges} from '@angular/core';
import { Router } from '@angular/router';
import { StudentProfileService } from 'app/services/student-profile.service';
import { PlyrComponent } from 'ngx-plyr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as snippet from './media-player.snippetcode';

@Component({
  selector: 'lecture-content',
  templateUrl: './lecture-content.component.html'

})

export class LectureContentComponent implements OnInit {
  // public

  // Input Decorator
  @Input() lecture: any;
  @Input() isRTL: boolean;
  @Input() chapterId: any;
  @Input() course: any;
  @Input() lectureId: any;
  youtube_url:string;
  assets:any;
  public plyr: PlyrComponent;
  public player: Plyr;
  public currentStudent:any;
  public plyrOptions = { tooltips: { controls: true } };
  private _unsubscribeAll: Subject<any>;
  // snippet code variables
  public _snippetCodeVideo = snippet.snippetCodeVideo;
  public _snippetCodeAudio = snippet.snippetCodeAudio;


 
  constructor(private router:Router, 
    private _studentService: StudentProfileService,) {
      this._unsubscribeAll = new Subject();
    }


    showLectureAsset(lectureId){
      this.router.navigateByUrl(`home/course/${this.course._id}/lecture/${lectureId}`);
    }
    
  ngOnInit(): void {
   this._getCurrentStudent();
     this.assets =  this.lecture?.assets.reduce(function (r, a) {
        r[a.section] = r[a.section] || [];
        r[a.section].push(a);
        return r;
    }, Object.create(null));
   
  }

_getCurrentStudent(){
    this._studentService.onSettingsChanged.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.currentStudent = this._studentService.currentUser; 
      }); 
}

  startWatchingVideo(){
    document.getElementById('watch-video-panel').style.display='none';
  }

  played(assetId){
    console.log(assetId);
  }
}
