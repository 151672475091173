import { Component, HostBinding, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: '[category-menu-horizontal-item]',
  templateUrl: './item.component.html'
})
export class CategoryMenuHorizontalItemComponent {
  @Input()
  item: any;
  
  @Input()
  appLanguage: any;

  public coreConfig: any;
  public isRTL:boolean;
  constructor( private router: Router) {}
  ngOnInit(): void {
    this.coreConfig = JSON.parse(localStorage.getItem('config'));
    this.isRTL = this.coreConfig.app.appLanguage=='ar' &&  this.coreConfig.layout.type=='horizontal'; 
  }

  navigateCourseList(courses,courseId){
    var str = courses.replace(/\s+/g, '-');
    courses = str.toLowerCase();
    this.router.navigateByUrl(`courses/${courses}/${courseId}`);
  }
}
